import React, {  } from 'react'
import { Link } from 'react-router-dom'
const currentYear = new Date().getFullYear();
const Footer = () => {
    

  return (
    <>
    
    <footer class="footer1 bg-img cover-background pt-2-5 pt-sm-6 pt-md-10" data-overlay-dark="8" style = {{backgroundImage: 'url("img/bg/bg-03.jpg")', backgroundSize: "contain",backgroundRepeat: "no-repeat"}}>
        <div class="container mb-6 pb-2-5 pb-md-5 border-bottom border-color-light-white">
            <div class="row justify-content-center mt-n1-9">
                <div class="col-sm-6 col-lg-4 mt-1-9 border-sm-end border-color-light-white">
                    <div class="text-sm-center">
                        <i class="fas fa-phone-alt text-white fs-2 d-block mb-4"></i>
                        {/* <h3 class="h6 text-white">Phone Number</h3> */}
                        <span><a href="tel:+915223541430" class="text-white opacity9">+91 522 3541430</a>, <a href="tel:++919415422074" class="text-white opacity9">+91 9415422074</a></span>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-4 mt-1-9 border-lg-end border-color-light-white">
                    <div class="text-sm-center">
                        <i class="far fa-envelope-open text-white fs-2 d-block mb-4"></i>
                        {/* <h3 class="h6 text-white">Email Address</h3> */}
                        <Link href="info@adventusindia.com" class="text-white opacity9">info@adventusindia.com</Link>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-4 mt-1-9">
                    <div class="text-sm-center">
                        <i class="fas fa-map-marker-alt text-white fs-2 d-block mb-4"></i>
                        {/* <h3 class="h6 text-white">Loaction</h3> */}
                        <span class="text-white opacity9">315, 2nd Floor, Sector 9, Vikas Nagar, Lucknow 226022 U.P. India</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row mt-n2-6">
                <div class="col-sm-6 col-lg-4 mt-2-6">
                    <div class="footer-logo mb-3">
                        <Link to={'/'}><img src="img/logos/footer-light-logo.png" alt="..." /></Link>
                    </div>
                    <p class="text-white mb-1-6 mb-lg-1-9 opacity9">Adventus Technocrats Pvt. Ltd India is the organization behind eAM. We are one of the leading School ERP solution companies in India. eAM is India's most preferred school erp software.</p>                    
                    <h3 class="h5 text-white text-capitalize mb-1-6 mb-lg-1-9">Follow Us</h3>
                        <div class="row g-2">
                            <ul class="footer-social-style1">
                            <li>
                                <a href="https://www.facebook.com/adventusin/" target="blank"><i class="fab fa-facebook-f"></i></a>
                            </li>
                            <li>
                                <a href="https://twitter.com/atplup" target="blank"><i class="fab fa-x"><img src='img/icons/twitter.png' style={{height:'15px'}} alt='' /></i></a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/@eam365" target="blank"><i class="fab fa-youtube"></i></a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/adventusindia/" target="blank"><i class="fab fa-linkedin-in"></i></a>
                            </li>
							<li>
                                <a href="https://www.instagram.com/adventusindia/" target="blank"><i class="fab fa-instagram"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-4 mt-2-6">
                    <div class="ps-sm-1-9 ps-xl-6">
                        <h3 class="h5 text-white text-capitalize mb-1-6 mb-lg-1-9">Insights</h3>
                        <ul class="footer-list-style1">
                            <li><Link to={'/Career'}>Career</Link></li>
                            <li><Link to={'https://adventusindia.com/pdf/adventus_profile.pdf'} target='blank'>Company Profile</Link></li>
                            <li><Link to={'/SchoolERP'}>School ERP</Link></li>
                            <li><Link to={'/Contact'}>Contact Us</Link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-4 mt-2-6">
                    <div class="ps-lg-1-9 ps-xl-6">
                        <h3 class="h5 text-white text-capitalize mb-1-6 mb-lg-1-9">More info</h3>
                        <ul class="footer-list-style1">
                            <li><Link to={'/Blog'}>Blog</Link></li>
                            <li><Link to={'https://eam.co.in'} target='blank'>eAM®</Link></li>                            
                            <li><Link to={'/Login'}>Support Portal</Link></li>
                            <li><Link to={'/Sitemap'}>Sitemap</Link></li>
                        </ul>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="footer-bar">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 text-center text-md-start mt-3 mt-md-0 order-2 order-md-1">
                        <p class="d-inline-block text-white mb-0 display-30 display-lg-29">&copy; <span class="current-year">{currentYear}</span> Adventus Technocrats Pvt. Ltd.</p>
                    </div>
                    <div class="col-md-6 text-md-end order-1 order-md-2">                        
                        <Link to={'/Privacy'} class="text-white">Privacy Policy</Link>&nbsp;<span class="text-white">|</span>&nbsp;  
                        <Link to={'/Terms'} class="text-white">Terms & Conditions</Link>&nbsp;<span class="text-white">|</span>&nbsp; 
                        <Link to={'/Disclaimer'} class="text-white">Disclaimer</Link>                   
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </>
  )
}

export default Footer
