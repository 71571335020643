import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrumb = (props) => {
  return (
    <>
       <section class="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7"  style={{backgroundImage:'url("img/bg/adventus-misc.png")'}}>
            <div class="row position-relative z-index-1">
                <div class="col-md-12">
                    <div class="faded-text">
                        <div class="f-text"><span class="main-font">{props.title}</span></div>
                    </div>
                    <h1>{props.title}</h1>
                    <ul class="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>{props.title}</span></li>
                    </ul>
                </div>
            </div>
        </section>
    </>
  )
}

export default Breadcrumb
