import './App.css';
import React, {} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Home from './Pages/Home';
import Navbar from './Common/Navbar';
import Footer from './Common/Footer';
import About from './Pages/About';
import Contact from './Pages/Contact';
import SchoolERP from './Pages/SchoolERP';
import Portfolio from './Pages/Portfolio';
import Clientele from './Pages/Clientele';
import ChannelPartner from './Pages/ChannelPartner';
import Services from './Pages/Services';
import Inquiry from './Common/Inquiry';
import BulkSMS from './Pages/BulkSMS';
import DomainRegistration from './Pages/DomainRegistration';
import EmailHosting from './Pages/EmailHosting';
import PaymentGateway from './Pages/PaymentGateway';
import SearchEngineOptimization from './Pages/SearchEngineOptimization';
import WebDesigning from './Pages/WebDesigning';
import WebHosting from './Pages/WebHosting';
import CompanyProfile from './Pages/CompanyProfile';
import Privacy from './Pages/Privacy';
import Terms from './Pages/Terms';
import Disclaimer from './Pages/Disclaimer';
import Education from './Pages/Education';
import TravelHospitality from './Pages/TravelHospitality';

import HealthcareFitness from './Pages/HealthcareFitness';
import MediaEntertainment from './Pages/MediaEntertainment';
import RealEstate from './Pages/RealEstate';
import Testimonials from './Pages/Testimonials';
import SchoolManagementSoftware from './Pages/SchoolManagementSoftware';
import SchoolMobileApp from './Pages/SchoolMobileApp';
import RFIDSolution from './Pages/RFIDSolution';
import CustomSoftwareDevelopment from './Pages/CustomSoftwareDevelopment';
import BiometricIntegration from './Pages/BiometricIntegration';
// import ScrollToTop from './Common/ScrollToTop';
import Career from './Pages/Career';
import Sitemap from './Pages/Sitemap';
import Login from './Pages/Login';
import CookieConsent from '../src/Common/CookieConsent';
function App() {
  
  return (
    <div className="main-wrapper">        
        <BrowserRouter>
        
          {/* <ScrollToTop/> */}
          <Inquiry />
          <Navbar />
          <Routes>
              <Route path='/' element={<Home/>}></Route>        
              <Route path='/About' element={<About />}></Route>
              <Route path='/Services' element={<Services/>}></Route>              
              <Route path='/SchoolERP' element={<SchoolERP/>}></Route>
              <Route path='/Portfolio' element={<Portfolio />}></Route>
              <Route path='/Clientele' element={<Clientele/>}></Route>
              <Route path='/ChannelPartner' element={<ChannelPartner/>}></Route>
              <Route path='/Contact' element={<Contact/>}></Route>     
              <Route path='/BulkSMS' element={<BulkSMS />}></Route>        
              <Route path='/DomainRegistration' element={<DomainRegistration />}></Route>
              <Route path='/EmailHosting' element={<EmailHosting/>}></Route>
              <Route path='/PaymentGateway' element={<PaymentGateway/>}></Route>
              <Route path='/SearchEngineOptimization' element={<SearchEngineOptimization />}></Route>
              <Route path='/WebDesigning' element={<WebDesigning/>}></Route>
              <Route path='/WebHosting' element={<WebHosting/>}></Route>
              <Route path='/CompanyProfile' element={<CompanyProfile/>}></Route>
              <Route path='/Privacy' element={<Privacy/>}></Route>
              <Route path='/Terms' element={<Terms/>}></Route>
              <Route path='/Disclaimer' element={<Disclaimer/>}></Route>
              <Route path='/Education' element={<Education/>}></Route>
              <Route path='/TravelHospitality' element={<TravelHospitality/>}></Route>            
              <Route path='/HealthcareFitness' element={<HealthcareFitness/>}></Route>
              <Route path='/MediaEntertainment' element={<MediaEntertainment/>}></Route>
              <Route path='/RealEstate' element={<RealEstate/>}></Route>
              <Route path='/Testimonials' element={<Testimonials/>}></Route>
              <Route path='/SchoolManagementSoftware' element={<SchoolManagementSoftware/>}></Route>
              <Route path='/SchoolMobileApp' element={<SchoolMobileApp/>}></Route>
              <Route path='/RFIDSolution' element={<RFIDSolution/>}></Route>
              <Route path='/CustomSoftwareDevelopment' element={<CustomSoftwareDevelopment/>}></Route>
              <Route path='/BiometricIntegration' element={<BiometricIntegration/>}></Route>
              <Route path='/Career' element={<Career/>}></Route>
              <Route path='/Sitemap' element={<Sitemap/>}></Route>
              <Route path='/Login' element={<Login/>}></Route>
          </Routes>
          <CookieConsent />
          <Footer />
        </BrowserRouter>        
    </div>
  );
}

export default App;
