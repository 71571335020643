import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const Clientele = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <div>
        <section class="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7"   style = {{backgroundImage: 'url("img/bg/bg-19.jpg")'}}>
            <div class="row position-relative z-index-1">
                <div class="col-md-12">
                    <div class="faded-text">
                        <div class="f-text"><span class="main-font">Clientele</span></div>
                    </div>
                    <h1>Clientele</h1>
                    <ul class="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>Clientele</span></li>
                    </ul>
                </div>
            </div>
        </section>

        <section>
            <div className="container">
                <div className="section-heading5">
                    {/* <span className="sub-title">Get in touch</span> */}
                    <h2>Our <span className="text-secondary">Clientele</span></h2>
                    <p>Adventus has popularly gained the attention and the trust of the customers by delivering them the best solutions for their needs. We have almost having 100+ customer-bases across the globe.</p>
                    <div className="divider"></div>
                </div>
            </div>
            <div className="container">            
                <div className="row mt-n1-9 mb-3">
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/IIM_Lucknow.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/BBD_University.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/pioneer-montessori-school-lucknow.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/rmp-pgcollege-sitapur.jpg" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/rd-memorial-public-school-lucknow.jpg" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Siddhartha-Global-School-Lucknow.jpg" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/DPS-Amethi.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/JCBest-Schools-Abuja.jpg" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/The_Feuerstein_Institute.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Bhonwal_Convent_School.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Suman-Hospital-Sultanpur.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/RDSPublicSchool.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Kings_Eden.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Shiv-Ram-Singh-Inter-College-Barabanki.jpg" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Sumitra-Group-of-Schools-Sitapur.jpg" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Divine-Public-School-Amethi.jpg" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/ChildrensAcademy.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/City-Modern-Academy-logo-1.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/red-rose-school-lucknow.jpg" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Nalanda_Public_Inter_College.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Himalayan-Group-Lucknow.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/LMPIC-Lucknow.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Lok-Bharti-School-Lucknow.png" className="main-img" alt="..." />
                                </div>		
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Nalanda_International_Academy.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/mic-logo.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/birla_lucknow.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>    
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/ojhas-classes-lucknow.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Harsh_College_Logo.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/PuresiensLogo.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">                
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Acacia_Biotech.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/DR-Thakkar-Ahmedabad.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Allana.JPEG" className="main-img" alt="..." />
                                </div>		
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Aditya_Birla_Group.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Avancar-Trade-Advisers-Lucknow.jpg" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Bolt-India-Lucknow.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Encardio-Rite-Lucknow.jpg" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Hombiz-Security-Lucknow.jpg" className="main-img" alt="..." />
                                </div>		
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Comaea_Consulting.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Indorama.jpg" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>   
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Shashi-Niketan-Lucknow.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>   
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Royale_Orchids_Banquets_Suites.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/JS_Microsystems.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Marina-Publications.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Zenex_Security.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Bridge_Corporation.jpg" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/UP_PWD.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/UP_Housing_Development_Board.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>
                    
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/BSE-Lucknow.jpg" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/JIMS-Lucknow.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    
                
                    
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Lucknow-International-Public-School-Lucknow.jpg" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/mgip-Lucknow.jpg" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/sbs-college-lucknow.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/SINPS.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>
                    
                    
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/NUS.jpg" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>
                    
                    
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/JIAMSH.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>
                    

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/CSIR.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>

                    

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Kedar-Sons.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/King_George_Medical_University.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>
                    

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Narendra_Deva_University_of_Agriculture_and_Technology.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Navyug_Logo.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Nova_College_Pharmacy.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Nova_Nursing_Institute.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/NowFloats.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/TheCentrum.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/viraj-constructions.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>

                    
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/division.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/PMM.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>                        
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Mother_Teresa_Inter_College_Raibareli.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div> 
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Sun_Power_Systems.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div> 
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Vidyarthi_Kendra.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div> 

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/malody-healthcare.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div> 
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Harsh_Pain_Management.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div> 
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/PSVT.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div> 
                    {/* <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Shri_Hanumat_Seva_Samiti.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>  */}
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/Sigma_Colonisers.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div>									
                                    <img src="img/clientele/DPG_Sansthan.jpg" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div className='pt-3'>									
                                    <img src="img/clientele/bhavishya-logo.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div className='pt-3'>									
                                    <img src="img/clientele/scientiic_dental.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div className='pt-4'>									
                                    <img src="img/clientele/phibonacci.svg" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div className='pt-0'>									
                                    <img src="img/clientele/aarambh_logo.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div className='pt-0'>									
                                    <img src="img/clientele/Sigma_Khabar.jpg" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>

                   

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div className='pt-0'>									
                                    <img src="img/clientele/SKU.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div className='pt-0'>									
                                    <img src="img/clientele/Sultanpur_Institute_Pharmacy.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div className='pt-0'>									
                                    <img src="img/clientele/Surya_Public_School.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div className='pt-0'>									
                                    <img src="img/clientele/SVNC_ITI.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div className='pt-0'>									
                                    <img src="img/clientele/The-Annapurna-Mess-Lucknow.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div className='pt-3'>									
                                    <img src="img/clientele/Vaidik_Home.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>

                    
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div className='pt-0'>									
                                    <img src="img/clientele/AHNV.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>

                    
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div className='pt-0'>									
                                    <img src="img/clientele/Gharanunda_Green.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div className='pt-0'>									
                                    <img src="img/clientele/GLC_Infotech.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div className='pt-3'>									
                                    <img src="img/clientele/Mentech_Security.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div className='pt-2'>									
                                    <img src="img/clientele/Navsatta.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div className='pt-0'>									
                                    <img src="img/clientele/SGPGI.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div className='pt-0'>									
                                    <img src="img/clientele/Abeez_Timber.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div className='pt-0'>									
                                    <img src="img/clientele/Atithi_Inn.jpg" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div className='pt-0'>									
                                    <img src="img/clientele/Raman_Groups.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                        <div className="card card-style4">
                            <div className="card-body">
                                <div className='pt-0'>									
                                    <img src="img/clientele/lemon-tree-hotels.png" className="main-img" alt="..." />
                                </div>	
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>        
        </section>	
		
    </div>
  )
}

export default Clientele
