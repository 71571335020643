import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

const Sitemap = () => {
    const [setActiveItem] = useState('home');

    const handleItemClick = (itemName) => {
        setActiveItem(itemName);
        console.log("clicked false")
        setClickedMenu(false)
    };

    const location = useLocation()
    console.log(location.pathname)

    const [setClickedMenu] = useState(false)

    let handleLink = () => {
        console.log("clicked false")
        setClickedMenu(false)
    }
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <div>
        <section class="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7"   style = {{backgroundImage: 'url("img/bg/bg-19.jpg")'}}>
            <div class="row position-relative z-index-1">
                <div class="col-md-12">
                    <div class="faded-text">
                        <div class="f-text"><span class="main-font">Sitemap</span></div>
                    </div>
                    <h1>Sitemap</h1>
                    <ul class="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>Sitemap</span></li>
                    </ul>
                </div>
            </div>
        </section>
        
        <section id="sec1" className='bg-light'>            
             <div className="container">
                <div className="section-heading5">                    
                    <h2>Sitemap</h2>
                    <div className="divider"></div>
                </div>
            </div>      
                <div className='container'>
                    <div class="row">              
                        <div className='col-md-3'>
                            <h6 className='pb-2'><Link to={'/'} onClick={() => handleItemClick('Home')}>Home</Link></h6>                            
                            <ul class="list-style2">                                
                                <li><Link to={'/SchoolERP'} onClick={() => handleItemClick('SchoolERP')}>School ERP</Link></li>
                                <li><Link to={'/Clientele'} onClick={() => handleItemClick('Clientele')}>Clientele</Link></li>
                                <li><Link to={'/ChannelPartner'} onClick={() => handleItemClick('ChannelPartner')}>Channel Partner</Link></li>
                                <li><Link onclick="scrollToTop()" to={'/Contact'} onClick={() => handleItemClick('Contact')}>Contact Us</Link></li>

                            </ul>
                        </div>         
                        <div className='col-md-3'>
                            <h6 className='pb-2'>About Us</h6>
                            <ul class="list-style2 pb-4">
                                <li><Link onClick={handleLink} to={'/About'}>About Adventus</Link></li>
                                <li><Link onClick={handleLink} to={'https://adventusindia.com/pdf/adventus_profile.pdf'} target='blank'>Company Profile</Link></li>
                                <li><Link onClick={handleLink} to={'/Testimonials'}>Client Testimonials</Link></li>
                            </ul>
                        </div>
                        <div className='col-md-3'>
                            <h6 className='pb-2'>Services</h6>
                                <ul class="list-style2">
                                    <li><Link onClick={handleLink} to={'/SchoolManagementSoftware'}>School Management Software</Link></li>
                                    <li><Link onClick={handleLink} to={'/SchoolMobileApp'}>School Mobile App</Link></li>
                                    <li><Link onClick={handleLink} to={'/RFIDSolution'}>RFID Solution</Link></li>
                                    <li><Link onClick={handleLink} to={'/WebDesigning'}>Website Designing</Link></li>
                                    <li><Link onClick={handleLink} to={'/CustomSoftwareDevelopment'}>Custom Software Development</Link></li>
                                    <li><Link onClick={handleLink} to={'/BulkSMS'}>Bulk SMS Integration</Link></li>
                                    <li><Link onClick={handleLink} to={'/BiometricIntegration'}>Biometric Integration</Link></li>
                                    <li><Link onClick={handleLink} to={'/PaymentGateway'}>Payment Gateway Integration</Link></li>
                                    <li><Link onClick={handleLink} to={'/DomainRegistration'}>Domain Name Registration</Link></li>
                                    <li><Link onClick={handleLink} to={'/WebHosting'}>Web Hosting</Link></li>
                                    <li><Link onClick={handleLink} to={'/EmailHosting'}>Email Hosting</Link></li>
                                </ul>
                        </div>
                        <div className='col-md-3'>
                            <h6 className='pb-2'>More info</h6>                            
                            <ul class="list-style2">
                                <li><Link to={'/Careers'}>Careers</Link></li>
                                <li><Link to={'https://adventusindia.com/pdf/adventus_profile.pdf'} target='blank'>Company Profile</Link></li>
                                <li><Link to={'/Login'}>Support Portal</Link></li>
                                <li><Link to={'/Privacy'}>Privacy Policy</Link></li>
                                <li><Link to={'/Terms'}>Terms & Conditions</Link></li>
                                <li><Link to={'/Disclaimer'}>Disclaimer</Link></li>
                            </ul>
                        </div>

                        

                    </div>
                </div>
            </section>
    
    </div>
  )
}

export default Sitemap
