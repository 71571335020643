import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const MediaEntertainment = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <div>
        <section className="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7" style ={{backgroundImage: 'url("img/bg/bg-19.jpg")'}}>
            <div className="row position-relative z-index-1">
                <div className="col-md-12">
                    <div className="faded-text">
                        <div className="f-text"><span className="main-font">Media & Entertainment</span></div>
                    </div>
                    <h1>Media & Entertainment</h1>
                    <ul className="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>Media & Entertainment</span></li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="bg-light">
            <div className="container">
                <div class="card-body">            
                    <div className="section-heading5">                       
                        <h2>Media & <span className="text-secondary">Entertainment</span></h2>
                        <div className="divider"></div>                        
                    </div>
                    {/* <h3>The media and entertainment industry is undergoing profound changes fueled by digital disruption</h3> */}
                        <p>The media and entertainment industry is undergoing profound changes fueled by digital disruption, shifting consumer preferences, and the rise of new platforms. This study investigates the current state of the industry, focusing on emerging trends and innovative solutions that redefine traditional practices. </p>
                        <p>This comprehensive study delves into the dynamic landscape of the media and entertainment industry, examining the transformative trends, addressing key challenges, and spotlighting innovative strategies that industry stakeholders employ to adapt to changing consumer behaviors and technological advancements. Through a combination of market analysis, case studies, and expert interviews, this research aims to provide a nuanced understanding of the evolving media and entertainment landscape and offer insights for sustainable growth.</p>
                </div>
            </div>
        </section>
    </div>
  )
}

export default MediaEntertainment
