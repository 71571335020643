import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

function CompanyProfile() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <div>
        <section className="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7" style ={{backgroundImage: 'url("img/bg/bg-19.jpg")'}}>
            <div className="row position-relative z-index-1">
                <div className="col-md-12">
                    <div className="faded-text">
                        <div className="f-text"><span className="main-font">Company Profile</span></div>
                    </div>
                    <h1>Company Profile</h1>
                    <ul className="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>Company Profile</span></li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="bg-light">
            <div className="container">
                <div class="card-body">            
                    <div className="section-heading5">                       
                        <h2>Company <span className="text-secondary">Profile</span></h2>
                        <div className="divider"></div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default CompanyProfile
