import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const HealthcareFitness = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <div>
        <section className="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7" style ={{backgroundImage: 'url("img/bg/bg-19.jpg")'}}>
            <div className="row position-relative z-index-1">
                <div className="col-md-12">
                    <div className="faded-text">
                        <div className="f-text"><span className="main-font">Healthcare & Fitness</span></div>
                    </div>
                    <h1>Healthcare & Fitness</h1>
                    <ul className="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>Healthcare & Fitness</span></li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="bg-light">
            <div className="container">
                <div class="card-body">            
                    <div className="section-heading5">                       
                        <h2>Healthcare & <span className="text-secondary">Fitness</span></h2>
                        <div className="divider"></div>                        
                    </div>
                    {/* <h3>The healthcare and fitness sectors are witnessing transformative changes </h3> */}
                    <p>The healthcare and fitness sectors are witnessing transformative changes driven by technological advancements, evolving consumer expectations, and global health considerations. This study investigates the current state of healthcare and fitness, focusing on emerging trends and innovative solutions that contribute to a healthier society. </p>
                    <p>This comprehensive study explores the intricate interplay between healthcare and fitness, examining current trends, addressing challenges, and highlighting innovative strategies that industry stakeholders employ to enhance overall well-being. Through a combination of data analysis, case studies, and expert insights, this research aims to provide a holistic understanding of the evolving healthcare and fitness landscape and offer practical recommendations for improved health outcomes.</p>
                </div>
            </div>
        </section>
    </div>
  )
}

export default HealthcareFitness
