import React, { useEffect, useState } from 'react'
import {Link, useNavigate } from 'react-router-dom';

const Login = () => {
    
let navigate =useNavigate();    
let [userValue, setuserValue]= useState("");
let [passValue, setPassValue]= useState("");
let [tick,setTick] = useState(false)
let [msg, setMesaage] = useState(null);

  
let EmailChange=(e)=>{
    setuserValue(e.target.value)
    console.log(e.target.value)
}

let PassChange=(e)=>{
     setPassValue(e.target.value)
     console.log(e.target.value)
}
  
let remmeberChange=(e)=>{
    setTick(e.target.checked)
      console.log(e.target.checked)
}
  let data = JSON.parse(localStorage.getItem('email')) || [];
  let submitButton=()=>{
    let obj={
       Email:userValue,
       Password:passValue,
       Remmeber:tick,
     }
     console.log(obj)

     let checkUserData=()=>{
        for(let i=0;i<data.length;i++){
            if(data[i].Email===obj.Email){
                if(data[i].Password===obj.Password){
                     navigate('/')
                     return;
                }
            }
        }
        setMesaage('invalid credentials!')
     }
     checkUserData();
    //  localStorage.setItem('logindetails', JSON.stringify(obj))
  }

useEffect(() => {
    window.scrollTo(0, 0);
}, [])

  return (
    <>
    <section className="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7" style ={{backgroundImage: 'url("img/bg/bg-19.jpg")'}}>
            <div className="row position-relative z-index-1">
                <div className="col-md-12">
                    <div className="faded-text">
                        <div className="f-text"><span className="main-font">Login</span></div>
                    </div>
                    <h1>Login</h1>
                    <ul className="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>Login</span></li>
                    </ul>
                </div>
            </div>
        </section>
    <main id="main" className='mt-0'>         
        <section className="inner-page">
            <div className="container">                
                <div className="form-signin mx-auto">      
                    <div className="section-heading5">                       
                        <h2><span className="text-secondary">Login</span></h2>
                        <div className="divider"></div>
                    </div>
                    <p className='fw-bold text-center text-danger'>{msg}</p>                    
                    <div className="form-floating">
                        <input type="email" className="form-control" value={userValue} onChange={EmailChange} placeholder="name@example.com" />
                        <label>Email address</label>
                    </div>
                    <div className="form-floating">
                        <input type="password" className="form-control" value={passValue} onChange={PassChange} placeholder="Password" />
                        <label>Password</label>
                    </div>

                    <div className="form-check text-start my-3 d-flex justify-content-left">
                        <div>
                            <input className="form-check-input" onChange={remmeberChange} type="checkbox"  id="flexCheckDefault" />
                            <label className="form-check-label" for="flexCheckDefault">
                                Remember me
                            </label>
                        </div>                        
                    </div>
                    <button className="btn btn-primary w-100 py-2" onClick={submitButton}>Sign in</button>  
                </div>   
            </div>
        </section>
    </main>
    </>
  )
}

export default Login
