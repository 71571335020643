import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const Career = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <div>
        <section class="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7"   style = {{backgroundImage: 'url("img/bg/bg-19.jpg")'}}>
            <div class="row position-relative z-index-1">
                <div class="col-md-12">
                    <div class="faded-text">
                        <div class="f-text"><span class="main-font">Career</span></div>
                    </div>
                    <h1>Career</h1>
                    <ul class="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>Career</span></li>
                    </ul>
                </div>
            </div>
        </section>
        
        <section className="bg-light">  
            <div className="container">
                <div className="section-heading5">
                    {/* <span className="sub-title">Get in touch</span> */}
                    <h2>Career</h2>
                    <div className="divider"></div>
                </div>
            </div>          
            <div class="container">
                <div class="row">   
                    <div className='col-md-12'>
                        <p>We are always on a lookout for young (at heart), enthusiastic, and spunky individuals to lend their best efforts, time and personality to Adventus. If you feel we ought to check you out, we most certainly will.</p>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-6 mb-5 mb-lg-0">
                        <div className="mt-1-9">
                            <div className="row g-0 align-items-center">
                                <div className="col-10">
                                    <div className="image1">
                                        <img src="/img/service/career.gif" alt="..." />
                                    </div>
                                </div>                            
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 ps-lg-2-2 ps-xl-2">  
                        <p className="mb-1-9">Write us at <a href='mailto:career@adventusindia.com'>career@adventusindia.com</a></p>
                        <p>Please mention name of the position you wish to apply in Subject Line.</p>
                        <h3>We've opening for</h3>
                        <ul class="list-style3 mb-4">
                            <li>Software Engineer: Excellent knowledge of C#, ASP.Net, .Net Framework, MS Sql Server 2012, Ajax, WCF and WPF (Minimum 2+ years relevant experience)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Career
