import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom'
import { BASE_URL, TO_Email, CC_Email,Subject_Contact,Subject_Enquiry,Subject_ChannelPartner } from '../Constant';
import ReCAPTCHA from "react-google-recaptcha";
import AppLoader from '../Common/AppLoader';
const ChannelPartner = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const initialFormData = {
        name: '',
        email: '',
        contactNo: '',
        message: '',
        // capcha:'',
      };
const [formData, setFormData] = useState(initialFormData);
const [isRecaptchaVerified, setRecaptchaVerified] = useState(false);
const [isLoading, setIsLoading] = useState(false);

// Use an object to store the validation status for each field
const [validationStatus, setValidationStatus] = useState({
name: true,
email: true,
contactNo: true,
message: true
//capcha:true
});
const resetFormData = () => {
    setFormData(initialFormData);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate email format if the input name is 'email'
    if (name === 'email') {
      setValidationStatus((prevStatus) => ({
        ...prevStatus,
        email: validateEmail(value)
      }));
      setFormData({ ...formData, [e.target.name]: e.target.value });
    
    }
    else{
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // Reset validation status when the user types in the field
        setValidationStatus({ ...validationStatus, [e.target.name]: true });
    }
  
  };
  const validateEmail = (email) => {
    // Simple email validation, you can enhance it based on your requirements
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleRecaptchaChange = (value) => {
    // This callback is triggered when reCAPTCHA is verified
    setRecaptchaVerified(true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
      const isValid = Object.keys(formData).every(key => {
        if (key === 'email' && !validateEmail(formData[key])) {
          setValidationStatus({ ...validationStatus, [key]: false });
          return false;
        }
        if (!formData[key]) {
          setValidationStatus({ ...validationStatus, [key]: false });
          return false;
        }
        return true;
      });
      if (!isValid) 
      {
        if(formData.name && formData.email && formData.contactNo && formData.message)
        {
            alert('Enter Email valid address');
        }
        else{
            alert('Please fill in all required fields.');
        }
        return;
      }
        // Check if reCAPTCHA is verified before submitting the form
    if (isRecaptchaVerified) 
    {
      setIsLoading(true);
        const apiUrl = BASE_URL;  // Update the URL to match your API endpoint
const requestData = {
  name: formData.name,
  email: formData.email,
  toEmail: TO_Email,
  ccEmail: CC_Email,
  subject: Subject_ChannelPartner,
  contact: formData.contactNo,
  message: formData.message
};
        fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
          })
            .then(response => response.text())
            .then(data => {
              // Handle the response data
              console.log('Response:', data);
          
              if (data.includes("Send Success")) {
                alert("Your Request is submitted Successfully");
                setIsLoading(false);
                resetFormData();
              } else {
                // Handle other cases if needed
                alert("Request failed");
                setIsLoading(false);
              }
            })
            .catch(error => {
              // Handle errors
              alert('Error:', error)
              setIsLoading(false);
              console.error('Error:', error);
            });
      } 
      else {
       // console.log("Please complete the reCAPTCHA verification.");
        alert('Please complete the reCAPTCHA verification.');
      }
    };
    

  return (
    <div>
          <AppLoader loading={isLoading} />
        <section class="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7"   style = {{backgroundImage: 'url("img/bg/bg-19.jpg")'}}>
            <div class="row position-relative z-index-1">
                <div class="col-md-12">
                    <div class="faded-text">
                        <div class="f-text"><span class="main-font">Channel Partner</span></div>
                    </div>
                    <h1>Channel Partner</h1>
                    <ul class="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>Channel Partner</span></li>
                    </ul>
                </div>
            </div>
        </section>
        
        <section className="bg-light">  
            <div className="container">
                <div className="section-heading5">
                    {/* <span className="sub-title">Get in touch</span> */}
                    <h2>Channel <span className="text-secondary">Partner</span></h2>
                    <div className="divider"></div>
                </div>
            </div>          
            <div class="container">
              <div className='row'>
              <div class="col-lg-7">      
                <div class="row align-items-center">                
                    <div class="col-lg-12 col-md-12">                    
                        <h2 class="h3 mb-4">Become a Channel Partner of Adventus for our innovative School ERP Software i.e. eAM®</h2>
                        <p class="mb-1-9">We are the leading School ERP Software in North India. We enable schools to automate everyday operations with eAM®.</p>
                        <h3 class="h4 mb-4">Refer &amp; Earn with Adventus Partner Program</h3>
                        <h3 class="h4 mb-4">Why Partner with Adventus?</h3>
                        <ul class="list-style3 mb-4">
                            <li>Lucrative Commission</li>
                            <li>Lifetime Incentive</li>
                            <li>Assistance in lead closure</li>
                            <li>Training and Presentation materials</li>
                            <li>Zero Setup cost</li>
                            <li>Priority Support</li>
                            <li>Partner Agreement</li>
                        </ul>
                        <h3 class="h4 mb-4">Who can Partner with Adventus?</h3>
                        <p>If you’re a Freelancer, Web development agency, Digital service provider, Individual designer, Teacher, Educationalist, Self Employed Individual, Consultant in the education industry or any one with good contacts become a partner now to take our vision forward for providing paperless solutions to the schools and colleges across the globe.</p>
                        {/* <h3 class="h4 mb-4">A form will be there with following fields:</h3>     */}
                        <h3 class="h5 mb-4">Interested in starting a profitable journey with Adventus?</h3>    
                        <p>Just fill the details below, our team will get in touch with you.</p> 
                                     
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                    <div className="p-1-9 p-lg-2-2 box-shadows h-100">                           
                    <form className="contact quform" onSubmit={handleSubmit}>
                                <div className="quform-elements">
                                    <div className="row">

                                        {/* <!-- Begin Text input element --> */}
                                        <div className="col-md-4 pb-2">                                           
                                            <div class="input-group quform-element form-group">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i className='fa fa-user'></i>
                                                </span>
                                                <input type="text" class="form-control" placeholder="Name" 
                                                aria-label="Input group example" aria-describedby="basic-addon1"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        {/* <!-- End Text input element -->

                                        <!-- Begin Text input element --> */}

                                        <div className="col-md-4 pb-2">                                           
                                            <div class="input-group quform-element form-group">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i className='fa fa-envelope'></i>
                                                </span>
                                                <input type="text" class="form-control" placeholder="Email" 
                                                aria-label="Input group example" 
                                                aria-describedby="basic-addon1"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                 />
                                            </div>
                                        </div>
                                       
                                        <div className="col-md-4 pb-2">                                           
                                            <div class="input-group quform-element form-group">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i className='fas fa-phone-alt fs2'></i>
                                                </span>
                                                <input type="text" class="form-control" 
                                                placeholder="Contact No." aria-label="Input group example" 
                                                aria-describedby="basic-addon1"
                                                name="contactNo"
                                                value={formData.contactNo}
                                                onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        {/* <!-- End Text input element -->

                                        <!-- Begin Textarea element --> */}
                                        <div className="col-md-12">
                                            <div className="quform-element form-group">                                                
                                                <div className="quform-input">
                                                    <textarea className="form-control" rows="3" placeholder="Message"
                                                      name="message"
                                                      value={formData.message}
                                                      onChange={handleChange}></textarea>
                                                  
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Textarea element -->

                                        <!-- Begin Captcha element --> */}
                                        <div className="col-md-4">
                                            <div className="quform-element">
                                                {/* <div className="form-group">
                                                    <div className="quform-input">
                                                        <input className="form-control" type="text" placeholder="Type the below word"
                                                            name="capcha"
                                                            value={formData.capcha}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className="form-group">
                                                    <div className="quform-captcha">
                                                        <div className="quform-captcha-inner">
                                                            {/* <img src="/img/courier-new-light.png" alt="..." /> */}
                                                           <ReCAPTCHA sitekey="6LeZjXYpAAAAALzSSr8D_JzY2j7DVV0C8A51Trc0" onChange={handleRecaptchaChange}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Captcha element -->

                                        <!-- Begin Submit button --> */}
                                        <div className="col-md-12">
                                            <div className="quform-submit-inner">
                                                <button type="submit" className="butn border-0"><i className="ti-arrow-right icon-arrow before"></i><span className="label">Submit</span><i className="ti-arrow-right icon-arrow after"></i></button>
                                            </div>                                            
                                        </div>
                                        {/* <!-- End Submit button --> */}

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 mt-5 pt-3'>
                      <h3 class="h4 mb-4">You can just drop a mail or call us</h3>
                      <p><b><i className='fa fa-envelope'></i></b> <a href='mailto:info@adventusindia.com'>info@adventusindia.com</a></p>
                      <p><b><i className='fas fa-phone-alt fs2'></i></b> <a href="tel:+919415422074">+91 9415422074</a></p>
                    </div>
                </div>
                </div>
                    <div className="col-lg-5">      
                      <div className="mt-1-9">
                          <div className="row g-0 align-items-center">
                              <div className="col-10">
                                <div className="image1"><img src="/img/content/adventus-channel-Partner.jpg" alt="..." /></div>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default ChannelPartner
