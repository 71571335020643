import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const DomainRegistration = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <div>       
        <section class="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7"   style = {{backgroundImage: 'url("img/bg/bg-19.jpg")'}}>
            <div class="row position-relative z-index-1">
                <div class="col-md-12">
                    <div class="faded-text">
                        <div class="f-text"><span class="main-font">Domain Registration</span></div>
                    </div>
                    <h1>Domain Registration</h1>
                    <ul class="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>Domain Registration</span></li>
                    </ul>
                </div>
            </div>
        </section>

        <section>
        <div className="container">
            <div className="section-heading5 mx-auto">                
                <h2>Domain <span className="text-secondary">Registration</span></h2>
                <div className="divider"></div>
             </div>
            <div className="row mt-n1-9 mb-3">
                <div className="col-md-12">
                    <p>Domain name registration is the process of reserving a specific domain name for use on the internet. This involves choosing a unique domain name, checking if it is available for use, and then registering it with a domain registrar. The domain registrar will then store the domain name in a central database known as the WHOIS database and assign the domain to the registrant. The registrant will have control over the domain name, including the ability to redirect it to a website, set up email accounts, and manage DNS records. Domain names are typically registered for a period of one to ten years and must be renewed periodically to maintain ownership.</p>
                    <p>Domain registration is the process of registering a domain name, which identifies one or more IP addresses with a name that is easier to remember and use in URLs to identify particular Web pages. The person or business that registers domain name is called the domain name registrant. Domain registration requires utilizing the services of a domain name registrar, an ICANN or national ccTLD accredited company that has the authority to register domain names. Registrars help individuals and organizations register a domain name that has an extension like .com, .org, .net, .info, .in, .biz, .us, .mobi, .name, .pro, .tv, etc.</p>
                </div>
            </div>
        </div>
        </section>
    </div>
  )
}

export default DomainRegistration
