import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const Education = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <div>
        <section className="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7" style ={{backgroundImage: 'url("img/bg/bg-19.jpg")'}}>
            <div className="row position-relative z-index-1">
                <div className="col-md-12">
                    <div className="faded-text">
                        <div className="f-text"><span className="main-font">Education</span></div>
                    </div>
                    <h1>Education</h1>
                    <ul className="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>Education</span></li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="bg-light">
            <div className="container">
                <div class="card-body">            
                    <div className="section-heading5">                       
                        <h2><span className="text-secondary">Education</span></h2>
                        <div className="divider"></div>
                    </div>
                    {/* <h3>eAM® gives a new approach to Educationists with remarkable information technology services.</h3> */}
                    <p>In the rapidly evolving landscape of education, institutions are seeking ways to adapt and thrive. This case study delves into the journey of an educational institution that embraced transformative teaching methods to address the changing needs of students and the demands of the modern world.</p>
                    <p>This case study examines the implementation of innovative teaching methodologies and technologies in an educational institution to enhance the learning experience and student outcomes. The focus is on a multi-faceted approach, incorporating digital tools, collaborative learning strategies, and adaptive assessment methods. The study explores the challenges faced, the strategies employed, and the measurable impact on both students and educators.</p>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Education
