import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const Testimonials = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, [])
  return (
    <div>
        <section className="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7" style ={{backgroundImage: 'url("img/bg/bg-19.jpg")'}}>
            <div className="row position-relative z-index-1">
                <div className="col-md-12">
                    <div className="faded-text">
                        <div className="f-text"><span className="main-font">Testimonials</span></div>
                    </div>
                    <h1>Testimonials</h1>
                    <ul className="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>Testimonials</span></li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="bg-light">
            <div className="container">
                <div class="card-body">            
                    <div className="section-heading5">                       
                        <h2>Client <span className="text-secondary">Testimonials</span></h2>
                        <div className="divider"></div>
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12 mb-3'>
                                <div class="testimonial5-carousel">                        
                                    <div class="text-center">
                                      <div class="testimonial-content">
                                          <div class="seprator">
                                            <i class="fas fa-quote-left fs-3 text-secondary"></i>
                                          </div>                                          
                                          <p class="mb-3">Adventus Technocrats Pvt. Ltd. have complteted our project request sucessfully and handed over the final deliverables 70% of the stipulated time. We really appriciate the thought put into the project by them and fullfillmnt of each small request at every stage of the project development. The output has been more than satisfactory and final tool has exceeded our initial expectations.</p>
                                          <h5 class="mb-1 text-primary">Sr. Admin Officer</h5>
                                          <span class="text-muted font-weight-500">Placement Cell IIM Lucknow</span>
                                      </div>                                      
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-12 mb-3'>
                                <div class="testimonial5-carousel">                        
                                    <div class="text-center">
                                      <div class="testimonial-content">
                                          <div class="seprator">
                                            <i class="fas fa-quote-left fs-3 text-secondary"></i>
                                          </div>                                         
                                          <p class="mb-3">I am writing this as a humble note of appreciation for implementing robust and result driver ERP software i.e. eAM at our various branches across Lucknow U.P. and also for outstanding technical support provided by your technical team. I would love to recommend your company to anyone who needs an ERP service in the future. Keep up the good work...</p>
                                          <h5 class="mb-1 text-primary">Dr. Prashant Kumar Mishra</h5>
                                        <span class="text-muted font-weight-500">MD, Red Rose Group of Schools Lucknow</span>
                                      </div>
                                      
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-12 mb-3'>
                                <div class="testimonial5-carousel">                        
                                    <div class="text-center">
                                      <div class="testimonial-content">
                                          <div class="seprator">
                                            <i class="fas fa-quote-left fs-3 text-secondary"></i>
                                          </div>                                          
                                          <p class="mb-3">Adventus and the team put a lot of work and thoughts in to the solution that I asked them to create for me. I really appreciate the effort they put in to it.</p>
                                          <h5 class="mb-1 text-primary">Mrs. Mamta Dixit</h5>
                                          <span class="text-muted font-weight-500">Principal, Lok Bharti Public Inter College Lucknow</span>
                                      </div>                                      
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-12 mb-3'>
                                <div class="testimonial5-carousel">                        
                                    <div class="text-center">
                                      <div class="testimonial-content">
                                          <div class="seprator">
                                            <i class="fas fa-quote-left fs-3 text-secondary"></i>
                                          </div>                                          
                                          <p class="mb-3">Have been associated with Adventus team for over 10+ years. They do almost all of our software development and domain management. Their skills in ERP is exemplary. In fact we were considering implementing ERP using MS Dynamics for our manufacturing, however with our experiences with Adventus team, we were compelled to go for their customized ERP solution. We have always been delighted with their services.</p>
                                          <h5 class="mb-1 text-primary">Dr. Ravindra Dubey</h5>
                                          <span class="text-muted font-weight-500">Saiens BioPharma Pvt. Ltd. Ahmedabad</span>
                                      </div>                                      
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-12 mb-3'>
                                <div class="testimonial5-carousel">                        
                                    <div class="text-center">
                                      <div class="testimonial-content">
                                          <div class="seprator">
                                            <i class="fas fa-quote-left fs-3 text-secondary"></i>
                                          </div>                                          
                                          <p class="mb-3">A very good and reputable company giving out the best management software for schools and other managerial organizations this software has eased up things for the school which is in Nigeria the software is also good in other countries. It’s the best software for management so far.</p>
                                          <h5 class="mb-1 text-primary">Onuorah Kezie Jasper</h5>
                                          <span class="text-muted font-weight-500">COO, JC Best Schools International Nigeria</span>
                                      </div>                                      
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-12 mb-3'>
                                <div class="testimonial5-carousel">                        
                                    <div class="text-center">
                                      <div class="testimonial-content">
                                          <div class="seprator">
                                            <i class="fas fa-quote-left fs-3 text-secondary"></i>
                                          </div>                                          
                                          <p class="mb-3">eAM is best school erp for CBSE Pattern schools.</p>
                                          <h5 class="mb-1 text-primary">Mr. S.K. Singh</h5>
                                          <span class="text-muted font-weight-500">Manager, Lucknow Model Public Inter College Lucknow</span>
                                      </div>                                      
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 mb-3'>
                                <div class="testimonial5-carousel">                        
                                    <div class="text-center">
                                      <div class="testimonial-content">
                                          <div class="seprator">
                                            <i class="fas fa-quote-left fs-3 text-secondary"></i>
                                          </div>                                          
                                          <p class="mb-3">Very helpful and supportive team , deals in all type of software solutions</p>
                                          <h5 class="mb-1 text-primary">Mr. Ayush Bajpai</h5>
                                          <span class="text-muted font-weight-500">RD Memorial Public School Lucknoww</span>
                                      </div>                                      
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 mb-3'>
                                <div class="testimonial5-carousel">                        
                                    <div class="text-center">
                                      <div class="testimonial-content">
                                          <div class="seprator">
                                            <i class="fas fa-quote-left fs-3 text-secondary"></i>
                                          </div>                                          
                                          <p class="mb-3">Great skills in their team , I am much impressed with their awesome & fast work.</p>
                                          <h5 class="mb-1 text-primary">Dr. Preetesh Saxena</h5>
                                          <span class="text-muted font-weight-500">Director, HITM Lucknow</span>
                                      </div>                                      
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 mb-3'>
                                <div class="testimonial5-carousel">                        
                                    <div class="text-center">
                                      <div class="testimonial-content">
                                          <div class="seprator">
                                            <i class="fas fa-quote-left fs-3 text-secondary"></i>
                                          </div>                                          
                                          <p class="mb-3">Best company for website</p>
                                          <h5 class="mb-1 text-primary">R.U. Dubey</h5>
                                          <span class="text-muted font-weight-500">Mentech Security Services Lucknow</span>
                                      </div>                                      
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 mb-3'>
                                <div class="testimonial5-carousel">                        
                                    <div class="text-center">
                                      <div class="testimonial-content">
                                          <div class="seprator">
                                            <i class="fas fa-quote-left fs-3 text-secondary"></i>
                                          </div>                                          
                                          <p class="mb-3">Excellent services</p>
                                          <h5 class="mb-1 text-primary">IP Agrahari</h5>
                                          <span class="text-muted font-weight-500">DPG Sansthan Lucknow</span>
                                      </div>                                      
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>                                                   
                </div>
            </div>
        </section>
    </div>
  )
}

export default Testimonials
