import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <div>
        <section className="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7" style ={{backgroundImage: 'url("img/bg/bg-19.jpg")'}}>
            <div className="row position-relative z-index-1">
                <div className="col-md-12">
                    <div className="faded-text">
                        <div className="f-text"><span className="main-font">Privacy Policy</span></div>
                    </div>
                    <h1>Privacy Policy</h1>
                    <ul className="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>Privacy Policy</span></li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="bg-light">
            <div className="container">
                <div class="card-body">            
                    <div className="section-heading5">
                        {/* <span className="sub-title">Get in touch</span> */}
                        <h2>Privacy <span className="text-secondary">Policy</span></h2>
                        <div className="divider"></div>
                    </div>            
                
                    <p class="w-lg-95">In order to send you information and product service offers that will more likely appeal to you, we may ask you to provide some information about yourself and your interests. Your participation is, of course, voluntary. We hope that you will want to take part in these special offers and opportunities, but we respect our customers' privacy.</p>
                    <div class="row mb-2-2">
                        <div class="col-lg-12">
                            <ul class="list-style3 mb-4">
                                <li> This site is designed, updated and maintained independently by Adventus.</li>
                                <li>The content is owned by Adventus You may not modify, publish, transmit, transfer, sell, reproduce, create derivative work from, distribute, repost, perform, display or in any way commercially exploit any of the content.</li>
                                <li>Adventus disclaims all warranties or conditions, whether expressed or implied, (including without limitation implied, warranties or conditions of information and context). We consider ourselves and intend to be subject to the jurisdiction only of the courts of Lucknow, U.P. India.</li>
                                <li>Adventus reserves the right, in its sole discretion, to suspend or cancel the service at any time if a computer virus, bug, or other technical problem corrupts the security, or proper administration of the service.</li>
                                <li>Adventus values the privacy of information pertaining to its associates. We do not use or disclose information about your individual visits to our website or any information that you may give us, such as your name, address, email address or telephone number, to any outside sources.</li>
                                <li>Adventus reserves the right to refuse service to anyone at any time.</li>
                                <li>Adventus will not use information about you without your permission and will provide the means for you to manage and control the information that you have provided. We will enable you to communicate your privacy concerns to us and that we will respond to them appropriately.</li>
                            </ul>
                            <p>Adventus does not disclose any personal information to advertisers and for other marketing and promotional purposes that could be used to personally identify you, such as your password, database, credit card number and bank account number. We hope that you will choose to personalize your experience on the Adventus website, and take advantage of the information and special offers that can be provided to you by registering on our site. If you would rather not participate, or if you think that we have information about you that is incorrect or you would like to change the information we have about you (if you re-locate, for example), let us know by contacting us at the e-mail info@adventusindia.com If you choose to register on our site, we may ask you to provide certain information about yourself (for example, your name, address, email address, or other information) and/or your company so that we may learn more about what interests you or your company and so that you and/or your company can be contacted in the future (for example, for special offers).</p>
                            <p>Our website uses "cookies." A cookie is an application that allows a site to be customized to the preferences of the user by placing a small file on the hard drive of your computer, tracking the user's navigation, and storing information about it. For example, cookies may allow you to keep from having to re-type your user name every time you access a website. If you are registered on the Adventus website, this information is personally identifiable. If you would rather not let us personalize your experience by using cookies, most web browsers have features that can inform you when a cookie is being sent and can allow users to opt-out of receiving cookies. If you are unsure of whether your program has this capability, you should contact the software manufacturer or your Internet service provider.</p>
                            <p>In order to improve our website and respond to the interests of our customers, we also collect certain information about the visits to our website by both members and non-members. For example, we may collect the domain name from which you access the Internet (e.g. "aol.com" for users accessing the Internet through America Online), as well as other information. If you are not a registered user on the Adventus website, this information is not identified to you personally. If you are a registered user, this information will be identifiable through the use of cookies.</p>
                            <p>We plan to use the information we collect in connection with updates, offers, and promotions made available to our customers and members. If you are interested, we will keep you informed of special offers on products and services offered to our members (for example we may send you an email or update your personalized screen). We will also occasionally compile aggregate information (i.e., information that does not identify any specific information that reflects the identity of an individual customer or member) for internal use at Adventus Technologies or to share with advertisers or other third-parties to help them become more familiar with our company and audience. Because we are continually striving to offer more features and services to our customers and website members, we may change our policy with respect to how we use your personal information. In the event that we change our practices, we will post a notice on this website, and we will provide you with the ability to "opt-out" of any changes. When you choose to explore other venues on the Internet, the operators of the other websites may have different privacy and data collection practices. We recommend that as you explore the Internet, you are aware of the privacy policies of other websites you visit. We cannot ensure that any site linked to or accessible from our page will protect your personal information and we cannot take responsibility for the practices of other websites.</p>
                            <p>If you would like to change or remove any of your personal information, you can contact us at <a href='mailto:info@adventusindia.com'>info@adventusindia.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

  )
}

export default Privacy
