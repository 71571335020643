import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const EmailHosting = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <div>
        <section class="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7"   style = {{backgroundImage: 'url("img/bg/bg-19.jpg")'}}>
            <div class="row position-relative z-index-1">
                <div class="col-md-12">
                    <div class="faded-text">
                        <div class="f-text"><span class="main-font">Email Hosting</span></div>
                    </div>
                    <h1>Email Hosting</h1>
                    <ul class="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>Email Hosting</span></li>
                    </ul>
                </div>
            </div>
        </section>

        <section>
        <div className="container">
            <div className="section-heading5 mx-auto">                
                <h2>Email <span className="text-secondary">Hosting</span></h2>
                <div className="divider"></div>
             </div>
            <div className="row mt-n1-9 mb-3">
                <div className="col-md-12">
                    <p>Email hosting is a service that provides email services and storage space for email accounts on remote servers. This allows users to access their emails from anywhere with an internet connection and provides additional security, reliability, and technical support compared to self-hosted email solutions. Some common email hosting providers include.</p>
                    <ul class="list-style3 mb-4">
                        <li>Google Workspace (formerly known as G Suite)</li>
                        <li>Microsoft 365</li>
                        <li>Zoho Mail</li>
                        <li>Rackspace Email</li>
                    </ul>
                </div>
            </div>
        </div>
        </section>
    </div>
  )
}

export default EmailHosting
