import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const RealEstate = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <div>
        <section className="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7" style ={{backgroundImage: 'url("img/bg/bg-19.jpg")'}}>
            <div className="row position-relative z-index-1">
                <div className="col-md-12">
                    <div className="faded-text">
                        <div className="f-text"><span className="main-font">Real Estate</span></div>
                    </div>
                    <h1>Real Estate</h1>
                    <ul className="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>Real Estate</span></li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="bg-light">
            <div className="container">
                <div class="card-body">            
                    <div className="section-heading5">                       
                        <h2>Real <span className="text-secondary">Estate</span></h2>
                        <div className="divider"></div>
                    </div>
                    {/* <h3>The real estate industry is undergoing profound changes driven by technological advancements</h3> */}
                    <p>The real estate industry is undergoing profound changes driven by technological advancements, demographic shifts, and economic factors. This study investigates the current state of the real estate market, focusing on emerging trends and innovative solutions that redefine traditional practices. </p>
                    <p>This comprehensive study delves into the multifaceted realm of the real estate industry, examining current trends, addressing challenges, and spotlighting innovative strategies that industry stakeholders employ to adapt to evolving market dynamics. Through a combination of market analysis, case studies, and expert insights, this research aims to provide a holistic understanding of the real estate landscape and offer practical recommendations for sustainable growth.</p>
                </div>
            </div>
        </section>
    </div>
  )
}

export default RealEstate
