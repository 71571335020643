import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {} from 'swiper/react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import 'swiper/css';
// import 'swiper/css/navigation';
// import { Autoplay, Pagination, Navigation } from 'swiper/modules';
const Home = () => {
    const options = {
        items: 1, // Set the number of items to display
        autoplay: true,
        autoplayTimeout: 3000, // Time between slides in milliseconds
        loop: true, // Enable loop
        nav: true, // Enable navigation arrows
    };
  let arr=[ 
        {
        img:'/img/banner/banner-01.jpg',
        // title:"IT Solutions Services",
        subtitle:"Tailor made school ERP",
        subtitleTwo:"Solution",
        // subtitleThree:"For Your Business",
        desc:"Customization as per school need."
     },
        {
        img:'/img/banner/banner-02.jpg',
        // title:"Technology & IT Solutions",
        subtitle:"Extremely User-friendly",
        subtitleTwo:"Interface",
        // subtitleThree:"For Your Business",
        desc:"Easy to use Parent portal, Teacher portal and Admin console."
     },
        {
        img:'/img/banner/banner-03.jpg',
        // title:"Digital IT Solutions",
        subtitle:"Fully Integrated",
        subtitleTwo:"modules",
        desc:"Payment Gateway, SMS Gateway and Biometric integration."
     },    

     {
        img:'/img/banner/banner-04.jpg',
        // title:"Digital IT Solutions",
        subtitle:"12+ years",
        subtitleTwo:"of experience",
        desc:"eAM® is a complete school ERP solution with 12+ years of experience."
     },   
     
     {
        img:'/img/banner/banner-06.jpg',
        // title:"Digital IT Solutions",
        subtitle:"Multiple Schools",
        subtitleTwo:"Integration",
        desc:"eAM® is Best suitable School ERP for Chain of Schools."
     },  

    ]

    const owlOptions = {
        items: 3, // Set the default number of items
        responsive: {
          0: {
            items: 1, // Set the number of items for screens smaller than 600px
          },
          600: {
            items: 3, // Set the number of items for screens between 600px and 992px
          },
          992: {
            items: 3, // Set the number of items for screens between 992px and 1200px
          },
          // Add more breakpoints as needed
        },
      };

      const [clicked,setClicked]= useState(false)
      let showForm=()=>{
        setClicked(true)
    }
    
    let closeForm=()=>{
        setClicked(false)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

  return (
    <>    
    <div>
        <div className="top-position1 z-index-1">
            <div className="slider-fade banner1">
                <OwlCarousel className='owl-theme portfolio1-carousel owl-carousel text-center' {...options}>
                    {arr.map((item)=>{
                            return <div class="text-center item bg-img primary-overlay" data-overlay-dark="85" style={{ backgroundImage: 'url(' + item.img + ')'}}>
                            <div class="h-100 w-100 mt-5 pt-4" style={{position:'absolute', zIndex:'9999999'}}>
                                <div class="d-table h-100 w-100">
                                    <div class="d-table-cell align-middle caption">
                                        <div class="overflow-hidden w-200 w-md-200 w-lg-200">                                        
                                            {/* <div className='banner-img text-center item bg-img primary-overlay'><img src={item.img} alt='' /></div> */}
                                            {/* <h3 className="alt-font text-secondary"><span className="me-2"></span>{item.title}</h3> */}
                                            <h1 className="text-white mx-auto mt-5 pt-5">{item.subtitle} <span className="stroke">{item.subtitleTwo}</span> {item.subtitleThree}</h1>
                                            <p>{item.desc}</p>
                                            <div className="py-4 mt-n1-9">
                                                <div className="mt-1-9">
                                                    <Link className="video_btn small" onClick={showForm}><i className="fas fa-play"></i></Link>
                                                </div>
                                                <Link to={'/Contact'} className="butn white me-4 mt-1-9"><i className="ti-arrow-right icon-arrow before white"></i><span className="label">reach now</span><i className="ti-arrow-right icon-arrow after"></i></Link>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>                            
                    </div> })}      

                    
                </OwlCarousel>
                {clicked && <div className="modal" style={{display:'block'}}>
                    <div className="modal-dialog modal-lg">                    
                        <div className="modal-content">                            
                           <button type="button" class="btn-close" aria-label="Close" onClick={closeForm}></button>
                            <iframe className='top_video' src="https://www.youtube.com/embed/EfUFFEgsWic?si=dti_MW-nLYcaacP5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    </div>

    <section className="info-style-01">
        <div className="container">
            <div className="row mt-n1-9">
                <div className="col-sm-6 col-lg-3 mt-1-9">
                    <div className="flip-box">
                        <div className="flip-box-inner">
                            <div className="flip-box-front">
                                <div className="d-table-cell align-middle">
                                    <img className="mb-4" src="img/icons/icon-01.png" alt="..." />
                                    <h4 className="mb-0 font-weight-500">eAM® School ERP</h4>
                                </div>
                            </div>
                            <div className="flip-box-back">
                                <div className="d-table-cell align-middle">
                                    <h4 className="mb-3 text-white">IT Management</h4>
                                    <p className="text-white w-90 w-xl-80 mx-auto mb-3 opacity9">eAM® is an initiative of today and tomorrow's innovation by Adventus Technocrats</p>
                                    <Link to={'https://eam.co.in/'} target='blank' className="link-btn white" href="#"><span className="link-text">Read More</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3 mt-1-9">
                    <div className="flip-box">
                        <div className="flip-box-inner">
                            <div className="flip-box-front">
                                <div className="d-table-cell align-middle">
                                    <img className="mb-4" src="img/icons/icon-02.png" alt="..." />
                                    <h4 className="mb-0 font-weight-500">Mobile App</h4>
                                </div>
                            </div>
                            <div className="flip-box-back">
                                <div className="d-table-cell align-middle">
                                    <h4 className="mb-3 text-white">Mobile App</h4>
                                    <p className="text-white w-90 w-xl-80 mx-auto mb-3 opacity9">eAM® Mobile App can be downloaded from the Google Play Store and user can login</p>
                                    <Link  className="link-btn white" to={'/SchoolMobileApp'}><span className="link-text">Read More</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3 mt-1-9">
                    <div className="flip-box">
                        <div className="flip-box-inner">
                            <div className="flip-box-front">
                                <div className="d-table-cell align-middle">
                                    <img className="mb-4" src="img/icons/icon-03.png" alt="..." />
                                    <h4 className="mb-0 font-weight-500">Website</h4>
                                </div>
                            </div>
                            <div className="flip-box-back">
                                <div className="d-table-cell align-middle">
                                    <h4 className="mb-3 text-white">Website</h4>
                                    <p className="text-white w-90 w-xl-80 mx-auto mb-3 opacity9">Responsive Website - Adventus has a team of UI/UX Developer who are expert in responsive</p>
                                    <Link to={'/WebDesigning'} className="link-btn white"><span className="link-text">Read More</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3 mt-1-9">
                    <div className="flip-box">
                        <div className="flip-box-inner">
                            <div className="flip-box-front">
                                <div className="d-table-cell align-middle">
                                    <img className="mb-4" src="img/icons/icon-04.png" alt="..." />
                                    <h4 className="mb-0 font-weight-500">Smart Integration</h4>
                                </div>
                            </div>
                            <div className="flip-box-back">
                                <div className="d-table-cell align-middle">
                                    <h4 className="mb-3 text-white">Smart Integration</h4>
                                    <p className="text-white w-90 w-xl-80 mx-auto mb-3 opacity9">eAM® is integrated with major Payment Gateways, Biometric Machines, SMS Gateway</p>
                                    {/* <Link className="link-btn white" href="#"><span className="link-text">Read More</span></Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


<section className="about py-3">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-5 mb-2 mb-lg-0">
                    <div className="mt-1-9">
                        <div className="row g-0 align-items-center">
                            <div className="col-12">
                                <div className="text-center d-flex justify-content-center">
                                    <img src="/img/logos/eAM.png" alt="..." />
                                </div>
                            </div>
                            {/* <div className="col-4">
                                <div className="image2">
                                    <img src="/img/content/about-02.jpg" alt="..." />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 ps-lg-2-5 ps-xl-7">                    
                    <h2 className="h1 mb-4">Adventus transforms businesses through information technology. </h2>
                    <p className="mb-1-9">Adventus Technocrats Pvt. Ltd India is the organization behind eAM. We are one of the leading School ERP solution companies in India. eAM is India's most preferred school erp software. We aspire to be the best School ERP software provider. We've Integrated School ERP Solution from Admission to TC.</p>
                    
                    <div className="d-sm-flex align-items-center">
                        <div className="mb-4 mb-sm-0">
                            <Link to={'/About'} className="butn"><i className="ti-arrow-right icon-arrow before"></i><span className="label">Read More</span><i className="ti-arrow-right icon-arrow after"></i></Link>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="cmt-bgimage-yes clearfix bg-light">
        <div className="section-heading5">                   
                <h2>Industries <span className="text-secondary">We Are Serving</span></h2>
                <p>eAM® gives a new approach to Educationists with remarkable information technology services.</p>
                <div className="divider"></div>
            </div>
        <div class="our-services" data-aos="fade-up">
            <div class="grid-1755">
                <div class="service-1 hover-service" data-aos="fade-up">
                <img src="img/content/product-bg1.jpg" alt="..." className='portfolio-img' />
                    <div class="service-brief">
                    <div class="elementor-flip-box-layer-overlay">
                        <h4>Education</h4>
                        <p>Give a new platform and approach to learning, the personalized learning experience with remarkable technology</p>
                        <div class="btn-inline-button">
                        <Link to={'/Education'} className="butn white me-4"><i className="ti-arrow-right icon-arrow before white"></i><span className="label">Case Study</span><i className="ti-arrow-right icon-arrow after"></i></Link>
                        </div>
                    </div>
                    </div>
                    <div class="elementor-flip-box-layer-overlay-1">
                    <h4>Education</h4>
                    </div>
                </div>
                <div class="service-2 hover-service" data-aos="fade-up">
                <img src="img/portfolio/Travel_Hospitality.png" alt="..." className='portfolio-img' />
                    <div class="service-brief">
                    <div class="elementor-flip-box-layer-overlay">
                        <h4>Travel &amp; Hospitality</h4>
                        <p>The travel and hospitality industry has undergone significant transformations in recent years</p>
                        <div class="btn-inline-button">
                            <Link to={'/TravelHospitality'} className="butn white me-4"><i className="ti-arrow-right icon-arrow before white"></i><span className="label">Case Study</span><i className="ti-arrow-right icon-arrow after"></i></Link>
                        </div>
                    </div>
                    </div>
                    <div class="elementor-flip-box-layer-overlay-1">
                    <h4>Travel &amp; Hospitality</h4>
                    </div>
                </div>
                <div class="service-3 hover-service" data-aos="fade-up">
                <img src="img/portfolio/Real_Estate.png" alt="..." className='portfolio-img' />
                    <div class="service-brief">
                    <div class="elementor-flip-box-layer-overlay">
                        <h4>Real Estate</h4>
                        <p>The real estate industry is undergoing profound changes driven by technological advancements</p>
                        <div class="btn-inline-button">
                            <Link to={'/RealEstate'} className="butn white me-4"><i className="ti-arrow-right icon-arrow before white"></i><span className="label">Case Study</span><i className="ti-arrow-right icon-arrow after"></i></Link>
                        </div>
                    </div>
                    </div>
                    <div class="elementor-flip-box-layer-overlay-1">
                    <h4>Real Estate</h4>
                    </div>
                </div>
                <div class="service-4 hover-service" data-aos="fade-up">
                <img src="img/portfolio/Healthcare_Fitness.png" alt="..." className='portfolio-img' />
                    <div class="service-brief">
                    <div class="elementor-flip-box-layer-overlay">
                        <h4>Healthcare &amp; Fitness</h4>
                        <p>The healthcare and fitness sectors are witnessing transformative changes</p>
                        <div class="btn-inline-button">
                            <Link to={'/HealthcareFitness'} className="butn white me-4"><i className="ti-arrow-right icon-arrow before white"></i><span className="label">Case Study</span><i className="ti-arrow-right icon-arrow after"></i></Link>
                        </div>
                    </div>
                    </div>
                    <div class="elementor-flip-box-layer-overlay-1">
                    <h4>Healthcare &amp; Fitness</h4>
                    </div>
                </div>
                <div class="service-5 hover-service" data-aos="fade-up">
                    <img src="img/portfolio/Media_Entertainment.png" alt="..." className='portfolio-img' />
                    <div class="service-brief">
                    <div class="elementor-flip-box-layer-overlay">
                        <h4>Media &amp; Entertainment</h4>
                        <p>The media and entertainment industry is undergoing profound changes fueled by digital disruption</p>
                        <div class="btn-inline-button">
                            <Link to={'/MediaEntertainment'} className="butn white me-4"><i className="ti-arrow-right icon-arrow before white"></i><span className="label">Case Study</span><i className="ti-arrow-right icon-arrow after"></i></Link>
                        </div>
                    </div>
                    </div>
                    <div class="elementor-flip-box-layer-overlay-1">
                    <h4>Media &amp; Entertainment</h4>
                    </div>
                </div>               
            </div>
        </div>
    </section>


    <section>
            <div className="container">
                <div className="section-heading5">                    
                    <h2>We partner with the world’s <span className="text-secondary">technology leaders</span></h2>
                    <div className="divider"></div>
                </div>
                <div className="row mt-n1-9">
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        {/* <div class="service-10 hover-service" data-aos="fade-up">
                            <img src="/img/partners/google.png" alt="..." className='portfolio-img' />
                            <div class="service-brief">
                                <div class="elementor-flip-box-layer-overlay">
                                    <h4>Google</h4>                                   
                                </div>
                            </div>
                            <div class="elementor-flip-box-layer-overlay-1">
                                <h4>Google</h4>
                            </div>
                        </div> */}
                                        
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 mb-3 bg-very-light-gray border border-color-extra-light-gray">
                                <img src="/img/partners/google.png" className="partner-logo" alt="..." />                                    
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 mb-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="/img/partners/Adobe-logo.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 mb-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/partners/microsoft.png" className="partner-logo" alt="..." />
                                </div>                               
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 mb-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/partners/Atlassian.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>               
                    </div>	
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 mb-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/partners/PayU.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 mb-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/partners/Razorpay_logo.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 mb-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/partners/NTT-Data-Logo.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 mb-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/partners/eazypay.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>               
                    </div>

                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 mb-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/partners/PayPal.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>               
                    </div>

                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 mb-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/partners/Paystack.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>               
                    </div>

                    
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 mb-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/partners/TeamViewer_logo.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 mb-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/partners/AnyDesk-logo.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 mb-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/partners/Tally_Logo.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
					
					<div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 mb-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/partners/essl-1.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 mb-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/partners/mantra-logo.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>               
                    </div>	
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 mb-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/partners/Sophos.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 mb-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/partners/Cisco.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 mb-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/partners/AmazonWebservices_Logo.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
					
                    
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 mb-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/partners/Microsoft_Azure.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>               
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 mb-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/partners/CtrlS.jpg" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>               
                    </div>

                </div>
            </div>
        </section>
        
    <section className="bg-light">
        <div className="container">
            <div className="section-heading5">
                <h2>Our  <span className="text-secondary">Services</span></h2>
                <p>Adventus is leading the way in innovation for over 12+ years, we build greater futures for businesses across the globe. We are here to help you conquer the ever changing tech world and boost your productivity and improve your earning potential.</p>
                <div className="divider"></div>
            </div>
        </div>
        <OwlCarousel className='owl-theme service-carousel owl-carousel container' loop margin={10} nav {...owlOptions}>
            <div class="featured-icon-box icon-align-top-content style1">
                <div class="bg_icon"><i className='fa'><img src='/img/icons/icon-09.png' className='faicons' alt='' /></i></div>
                <div class="featured-icon">
                    <div class="cmt-icon cmt-icon_element-onlytxt cmt-icon_element-color-darkgrey cmt-icon_element-size-lg">
                        <i class="fa"><img src='/img/icons/icon-09.png' className='faiconsTwo' alt='' /></i>
                    </div>
                </div>
                <div class="featured-title">
                    <h3>Mobile App</h3>
                </div>
                <div class="featured-hover-content">
                    <div class="featured-title">
                        <h3>Mobile App</h3>
                    </div>
                    <div class="featured-desc">
                        <p>One-Stop integrated solution on your palm.</p>
                    </div>
                    <Link to={'/SchoolMobileApp'} class="cmt-btn btn-inline cmt-icon-btn-right cmt-btn-size-md cmt-btn-color-white">Read More <i class="ti-arrow-right icon-arrow after px-1"></i></Link>
                </div>
            </div>

            <div class="featured-icon-box icon-align-top-content style1">
                <div class="bg_icon"><i className='fa'><img src='/img/icons/icon-10.png' className='faicons' alt='' /></i></div>
                <div class="featured-icon">
                    <div class="cmt-icon cmt-icon_element-onlytxt cmt-icon_element-color-darkgrey cmt-icon_element-size-lg">
                        <i className='fa'><img src='/img/icons/icon-10.png' className='faiconsTwo' alt='' /></i>
                    </div>
                </div>
                <div class="featured-title">
                    <h3>School Management Software</h3>
                </div>
                <div class="featured-hover-content">
                    <div class="featured-title">
                        <h3>School Management Software</h3>
                    </div>
                    <div class="featured-desc">
                        <p>we are always ensure to give our best work for our customer.</p>
                    </div>
                    <Link to={'/SchoolManagementSoftware'} class="cmt-btn btn-inline cmt-icon-btn-right cmt-btn-size-md cmt-btn-color-white">Read More <i class="ti-arrow-right icon-arrow after px-1"></i></Link>
                </div>
            </div>

            <div class="featured-icon-box icon-align-top-content style1">
                <div class="bg_icon"><i className='fa'><img src='/img/icons/icon-09.png' className='faicons' alt='' /></i></div>
                <div class="featured-icon">
                    <div class="cmt-icon cmt-icon_element-onlytxt cmt-icon_element-color-darkgrey cmt-icon_element-size-lg">
                        <i className='fa'><img src='/img/icons/icon-09.png' className='faiconsTwo' alt='' /></i>
                    </div>
                </div>
                <div class="featured-title">
                    <h3>School Mobile App</h3>
                </div>
                <div class="featured-hover-content">
                    <div class="featured-title">
                        <h3>School Mobile App</h3>
                    </div>
                    <div class="featured-desc">
                        <p>we are always ensure to give our best work for our customer.</p>
                    </div>
                    <Link to={'/SchoolMobileApp'} class="cmt-btn btn-inline cmt-icon-btn-right cmt-btn-size-md cmt-btn-color-white">Read More <i class="ti-arrow-right icon-arrow after px-1"></i></Link>
                </div>
            </div>

            <div class="featured-icon-box icon-align-top-content style1">
                <div class="bg_icon"><i className='fa'><img src='/img/icons/icon-11.png' className='faicons' alt='' /></i></div>
                <div class="featured-icon">
                    <div class="cmt-icon cmt-icon_element-onlytxt cmt-icon_element-color-darkgrey cmt-icon_element-size-lg">
                        <i className='fa'><img src='/img/icons/icon-11.png' className='faiconsTwo' alt='' /></i>
                    </div>
                </div>
                <div class="featured-title">
                    <h3>RFID Solution</h3>
                </div>
                <div class="featured-hover-content">
                    <div class="featured-title">
                        <h3>RFID Solution</h3>
                    </div>
                    <div class="featured-desc">
                        <p>we are always ensure to give our best work for our customer.</p>
                    </div>
                    <Link to={'/RFIDSolution'} class="cmt-btn btn-inline cmt-icon-btn-right cmt-btn-size-md cmt-btn-color-white">Read More <i class="ti-arrow-right icon-arrow after px-1"></i></Link>
                </div>
            </div>

            <div class="featured-icon-box icon-align-top-content style1">
                <div class="bg_icon"><i className='fa'><img src='/img/icons/icon-12.png' className='faicons' alt='' /></i></div>
                <div class="featured-icon">
                    <div class="cmt-icon cmt-icon_element-onlytxt cmt-icon_element-color-darkgrey cmt-icon_element-size-lg">
                        <i className='fa'><img src='/img/icons/icon-12.png' className='faiconsTwo' alt='' /></i>
                    </div>
                </div>
                <div class="featured-title">
                    <h3>Website Designing</h3>
                </div>
                <div class="featured-hover-content">
                    <div class="featured-title">
                        <h3>Website Designing</h3>
                    </div>
                    <div class="featured-desc">
                        <p>we are always ensure to give our best work for our customer.</p>
                    </div>
                    <Link to={'/WebDesigning'} class="cmt-btn btn-inline cmt-icon-btn-right cmt-btn-size-md cmt-btn-color-white">Read More <i class="ti-arrow-right icon-arrow after px-1"></i></Link>
                </div>
            </div>

            <div class="featured-icon-box icon-align-top-content style1">
                <div class="bg_icon"><i className='fa'><img src='/img/icons/icon-13.png' className='faicons' alt='' /></i></div>
                <div class="featured-icon">
                    <div class="cmt-icon cmt-icon_element-onlytxt cmt-icon_element-color-darkgrey cmt-icon_element-size-lg">
                        <i className='fa'><img src='/img/icons/icon-13.png' className='faiconsTwo' alt='' /></i>
                    </div>
                </div>
                <div class="featured-title">
                    <h3>Custom Software Development</h3>
                </div>
                <div class="featured-hover-content">
                    <div class="featured-title">
                        <h3>Custom Software Development</h3>
                    </div>
                    <div class="featured-desc">
                        <p>we are always ensure to give our best work for our customer.</p>
                    </div>
                    <Link to={'/CustomSoftwareDevelopment'} class="cmt-btn btn-inline cmt-icon-btn-right cmt-btn-size-md cmt-btn-color-white">Read More <i class="ti-arrow-right icon-arrow after px-1"></i></Link>
                </div>
            </div>

            <div class="featured-icon-box icon-align-top-content style1">
                <div class="bg_icon"><i className='fa'><img src='/img/icons/icon-09.png' className='faicons' alt='' /></i></div>
                <div class="featured-icon">
                    <div class="cmt-icon cmt-icon_element-onlytxt cmt-icon_element-color-darkgrey cmt-icon_element-size-lg">
                        <i className='fa'><img src='/img/icons/icon-09.png' className='faiconsTwo' alt='' /></i>
                    </div>
                </div>
                <div class="featured-title">
                    <h3>Bulk SMS Integration</h3>
                </div>
                <div class="featured-hover-content">
                    <div class="featured-title">
                        <h3>Bulk SMS Integration</h3>
                    </div>
                    <div class="featured-desc">
                        <p>we are always ensure to give our best work for our customer.</p>
                    </div>
                    <Link to={'/BulkSMS'} class="cmt-btn btn-inline cmt-icon-btn-right cmt-btn-size-md cmt-btn-color-white">Read More <i class="ti-arrow-right icon-arrow after px-1"></i></Link>
                </div>
            </div>

            <div class="featured-icon-box icon-align-top-content style1">
                <div class="bg_icon"><i className='fa'><img src='/img/icons/icon-12.png' className='faicons' alt='' /></i></div>
                <div class="featured-icon">
                    <div class="cmt-icon cmt-icon_element-onlytxt cmt-icon_element-color-darkgrey cmt-icon_element-size-lg">
                    <i className='fa'><img src='/img/icons/icon-12.png' className='faiconsTwo' alt='' /></i>
                    </div>
                </div>
                <div class="featured-title">
                    <h3>Biometric Integration</h3>
                </div>
                <div class="featured-hover-content">
                    <div class="featured-title">
                        <h3>Biometric Integration</h3>
                    </div>
                    <div class="featured-desc">
                        <p>we are always ensure to give our best work for our customer.</p>
                    </div>
                    <Link to={'/BiometricIntegration'} class="cmt-btn btn-inline cmt-icon-btn-right cmt-btn-size-md cmt-btn-color-white">Read More <i class="ti-arrow-right icon-arrow after px-1"></i></Link>
                </div>
            </div>

            <div class="featured-icon-box icon-align-top-content style1">
                <div class="bg_icon"><i className='fa'><img src='/img/icons/icon-10.png' className='faicons' alt='' /></i></div>
                <div class="featured-icon">
                    <div class="cmt-icon cmt-icon_element-onlytxt cmt-icon_element-color-darkgrey cmt-icon_element-size-lg">
                        <i className='fa'><img src='/img/icons/icon-10.png' className='faiconsTwo' alt='' /></i>
                    </div>
                </div>
                <div class="featured-title">
                    <h3>Payment Gateway Integration</h3>
                </div>
                <div class="featured-hover-content">
                    <div class="featured-title">
                        <h3>Payment Gateway Integration</h3>
                    </div>
                    <div class="featured-desc">
                        <p>we are always ensure to give our best work for our customer.</p>
                    </div>
                    <Link to={'/PaymentGateway'} class="cmt-btn btn-inline cmt-icon-btn-right cmt-btn-size-md cmt-btn-color-white">Read More <i class="ti-arrow-right icon-arrow after px-1"></i></Link>
                </div>
            </div>

            <div class="featured-icon-box icon-align-top-content style1">
                <div class="bg_icon"><i className='fa'><img src='/img/icons/icon-11.png' className='faicons' alt='' /></i></div>
                <div class="featured-icon">
                    <div class="cmt-icon cmt-icon_element-onlytxt cmt-icon_element-color-darkgrey cmt-icon_element-size-lg">
                        <i className='fa'><img src='/img/icons/icon-11.png' className='faiconsTwo' alt='' /></i>
                    </div>
                </div>
                <div class="featured-title">
                    <h3>Domain Name Registration</h3>
                </div>
                <div class="featured-hover-content">
                    <div class="featured-title">
                        <h3>Domain Name Registration</h3>
                    </div>
                    <div class="featured-desc">
                        <p>we are always ensure to give our best work for our customer.</p>
                    </div>
                    <Link to={'/DomainRegistration'} class="cmt-btn btn-inline cmt-icon-btn-right cmt-btn-size-md cmt-btn-color-white">Read More <i class="ti-arrow-right icon-arrow after px-1"></i></Link>
                </div>
            </div>
           
            <div class="featured-icon-box icon-align-top-content style1">
                <div class="bg_icon"><i className='fa'><img src='/img/icons/icon-08.png' className='faicons' alt='' /></i></div>
                <div class="featured-icon">
                    <div class="cmt-icon cmt-icon_element-onlytxt cmt-icon_element-color-darkgrey cmt-icon_element-size-lg">
                        <i className='fa'><img src='/img/icons/icon-08.png' className='faiconsTwo' alt='' /></i>
                    </div>
                </div>
                <div class="featured-title">
                    <h3>Web Hosting</h3>
                </div>
                <div class="featured-hover-content">
                    <div class="featured-title">
                        <h3>Web Hosting</h3>
                    </div>
                    <div class="featured-desc">
                        <p>we are always ensure to give our best work for our customer.</p>
                    </div>
                    <Link to={'/WebHosting'} class="cmt-btn btn-inline cmt-icon-btn-right cmt-btn-size-md cmt-btn-color-white">Read More <i class="ti-arrow-right icon-arrow after px-1"></i></Link>
                </div>
            </div>
           
            <div class="featured-icon-box icon-align-top-content style1">
                <div class="bg_icon"><i className='fa'><img src='/img/icons/icon-13.png' className='faicons' alt='' /></i></div>
                <div class="featured-icon">
                    <div class="cmt-icon cmt-icon_element-onlytxt cmt-icon_element-color-darkgrey cmt-icon_element-size-lg">
                        <i className='fa'><img src='/img/icons/icon-13.png' className='faiconsTwo' alt='' /></i>
                    </div>
                </div>
                <div class="featured-title">
                    <h3>Email Hosting</h3>
                </div>
                <div class="featured-hover-content">
                    <div class="featured-title">
                        <h3>Email Hosting</h3>
                    </div>
                    <div class="featured-desc">
                        <p>we are always ensure to give our best work for our customer.</p>
                    </div>
                    <Link to={'/EmailHosting'} class="cmt-btn btn-inline cmt-icon-btn-right cmt-btn-size-md cmt-btn-color-white">Read More <i class="ti-arrow-right icon-arrow after px-1"></i></Link>
                </div>
            </div>
        </OwlCarousel>        
    </section>

   
		
	<section>
        <div className="container">
            <div className="section-heading5 mx-auto">                
                <h2>Our <span className="text-secondary">Clientele</span></h2>
                <p>Adventus has popularly gained the attention and the trust of the customers by delivering them the best solutions for their needs. We have almost having 100+ customer-bases across the globe.</p>
                <div className="divider"></div>
            </div>
            <div className="row mt-n1-9 mb-3">
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/IIM_Lucknow.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/BBD_University.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>                        
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/pioneer-montessori-school-lucknow.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/rmp-pgcollege-sitapur.jpg" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/rd-memorial-public-school-lucknow.jpg" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>

                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Siddhartha-Global-School-Lucknow.jpg" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/DPS-Amethi.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/JCBest-Schools-Abuja.jpg" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/The_Feuerstein_Institute.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>                        
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Bhonwal_Convent_School.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>                        
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Suman-Hospital-Sultanpur.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/RDSPublicSchool.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>                        
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Kings_Eden.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>                        
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Shiv-Ram-Singh-Inter-College-Barabanki.jpg" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Sumitra-Group-of-Schools-Sitapur.jpg" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Divine-Public-School-Amethi.jpg" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/ChildrensAcademy.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>                        
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/City-Modern-Academy-logo-1.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/red-rose-school-lucknow.jpg" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Nalanda_Public_Inter_College.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>                        
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Himalayan-Group-Lucknow.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/LMPIC-Lucknow.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Lok-Bharti-School-Lucknow.png" className="main-img" alt="..." />
                            </div>		
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Nalanda_International_Academy.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>                        
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/mic-logo.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/birla_lucknow.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>                        
                </div>    
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/ojhas-classes-lucknow.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Harsh_College_Logo.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/PuresiensLogo.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>                        
                </div>

                <div className="col-md-3 col-xl-3 col-6 mt-1-9">                
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Acacia_Biotech.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/DR-Thakkar-Ahmedabad.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>

                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Allana.JPEG" className="main-img" alt="..." />
                            </div>		
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Aditya_Birla_Group.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>                        
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Avancar-Trade-Advisers-Lucknow.jpg" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>
              
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Bolt-India-Lucknow.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Encardio-Rite-Lucknow.jpg" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Hombiz-Security-Lucknow.jpg" className="main-img" alt="..." />
                            </div>		
                        </div>
                    </div>
                </div>

                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Comaea_Consulting.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>                        
                </div>
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Indorama.jpg" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>                        
                </div>   
                <div className="col-md-3 col-xl-3 col-6 mt-1-9">
                    <div className="card card-style4">
                        <div className="card-body">
                            <div>									
                                <img src="img/clientele/Shashi-Niketan-Lucknow.png" className="main-img" alt="..." />
                            </div>	
                        </div>
                    </div>
                </div>   
            </div>
        </div>
        <div className='row'>
                <div className='col-md-12'>
                    <div className='text-center mt-4'>
                        <div className="service-button">
                                <Link to={'/Clientele'} className="butn md"><i className="ti-arrow-right icon-arrow before"></i><span className="label">View More</span><i className="ti-arrow-right icon-arrow after"></i></Link>
                            </div>
                    </div>
                </div>
        </div>
    </section>
		

    <section className="bg-light">
        <div className="container">
            <div className="section-heading5">                
                <h2>Why Choosing <span className="text-secondary">Adventus </span></h2>
                <p>Adventus is absolutely focused towards providing reliable and robust solutions equipped with comprehensive market research and business strategies.</p>
                <div className="divider"></div>
            </div>
            <div className="row mt-n1-9">
                <div className="col-sm-6 col-lg-3 mt-2-2">
                    <div className="process-style1">
                        <div className="process-number-wrap">
                            <div className="process-number">01</div>
                        </div>
                        <div className="text-center box-shadows p-1-6 p-xl-1-9">
                            <h4 className="mb-3">6+ Years of Experience</h4>
                            <p className="mb-0 w-sm-95 mx-auto">Celebrating 6+ years! Let's take the moment to reflect milestone.</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3 mt-2-2">
                    <div className="process-style1 last">
                        <div className="process-number-wrap">
                            <div className="process-number">02</div>
                        </div>
                        <div className="text-center box-shadows p-1-6 p-xl-1-9">
                            <h4 className="mb-3">4+ Countries</h4>       
                            <p className="mb-0 w-sm-95 mx-auto">Our great work has been reached across the global clients & their satisfaction for their needs.</p>                     
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3 mt-2-2">
                    <div className="process-style1">
                        <div className="process-number-wrap">
                            <div className="process-number">03</div>
                        </div>
                        <div className="text-center box-shadows p-1-6 p-xl-1-9">
                            <h4 className="mb-3">100+ Satisfied Clients</h4>
                            <p className="mb-0 w-sm-95 mx-auto">Adventus has popularly gained the attention and the trust of the clients.</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3 mt-2-2">
                    <div className="process-style1">
                        <div className="process-number-wrap">
                            <div className="process-number">04</div>
                        </div>
                        <div className="text-center box-shadows p-1-6 p-xl-1-9">
                            <h4 className="mb-3">25+ Technocrats</h4> 
                            <p className="mb-0 w-sm-95 mx-auto">Our team brings a diverse set of minds and experiences that come together in beautiful ways.</p>                           
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </section>

 
    <section className="position-relative z-index-9 pb-0">
        <div className="parallax bg-img section-bg h-700 min-height-550 primary-overlay" data-overlay-dark="9" style = {{backgroundImage: 'url("img/bg/bg-01.jpg")', backgroundSize: "contain",backgroundRepeat: "no-repeat"}}></div>
        <div className="container mb-xl-10">
            <div className="row justify-content-center position-relative z-index-9">
                <div className="col-lg-12">
                    <div className="section-heading5">
                        <h2 className="h1 text-white">Electronic Administration and <span className="text-secondary">Management</span> </h2>
                        <div className="divider"></div>
                    </div>
                </div>                
            </div>
        </div>

        <OwlCarousel className='owl-theme portfolio1-carousel owl-carousel text-center' loop nav {...owlOptions}>
            <div className="portfolio-style1 pb-4">
                <div className="portfolio-img">
                    <img src="/img/portfolio/portfolio-01.jpg" alt="..." />
                </div>
                <div className="portfolio-content">
                    <div className="item">
                        <span className="text-white d-block mb-2 display-29"><span className="me-2"></span>Mobile App</span>
                        <Link href="#"><h4 className="text-white">One-Stop integrated solution on your palm.</h4></Link>
                    </div>
                    <Link href="#" className="portfolio-link"><i className="ti-link fs-4"></i></Link>
                </div>
            </div>
            <div className="portfolio-style1 pb-4">
                <div className="portfolio-img">
                    <img src="/img/portfolio/portfolio-02.jpg" alt="..." />
                </div>
                <div className="portfolio-content">
                    <div className="item">
                        <span className="text-white d-block mb-2 display-29"><span className="me-2"></span>Media</span>
                        <Link href="#"><h4 className="text-white">Dedicated Mobile App for Parents.</h4></Link>
                    </div>
                    <Link href="#" className="portfolio-link"><i className="ti-link fs-4"></i></Link>
                </div>
            </div>
            <div className="portfolio-style1 pb-4">
                <div className="portfolio-img">
                    <img src="/img/portfolio/portfolio-03.jpg" alt="..." />
                </div>
                <div className="portfolio-content">
                    <div className="item">
                        <span className="text-white d-block mb-2 display-29"><span className="me-2"></span>Networking</span>
                        <Link href="#"><h4 className="text-white">Easy to use Online fees payment for parents from anywhere, at any time using the app.</h4></Link>
                    </div>
                    <Link href="#" className="portfolio-link"><i className="ti-link fs-4"></i></Link>
                </div>
            </div>
            <div className="portfolio-style1 pb-4">
                <div className="portfolio-img">
                    <img src="/img/portfolio/portfolio-04.jpg" alt="..." />
                </div>
                <div className="portfolio-content">
                    <div className="item">
                        <span className="text-white d-block mb-2 display-29"><span className="me-2"></span>IT Technology</span>
                        <Link href="#"><h4 className="text-white">Admin Console: All admin related details at a Glance at the Dashboard.</h4></Link>
                    </div>
                    <Link href="#" className="portfolio-link"><i className="ti-link fs-4"></i></Link>
                </div>
            </div>
            <div className="portfolio-style1 pb-4">
                <div className="portfolio-img">
                    <img src="/img/portfolio/portfolio-05.jpg" alt="..." />
                </div>
                <div className="portfolio-content">
                    <div className="item">
                        <span className="text-white d-block mb-2 display-29"><span className="me-2"></span>Product</span>
                        <Link href="#"><h4 className="text-white">Product Design</h4></Link>
                    </div>
                    <Link href="#" className="portfolio-link"><i className="ti-link fs-4"></i></Link>
                </div>
            </div>
            <div className="portfolio-style1 pb-4">
                <div className="portfolio-img">
                    <img src="/img/portfolio/portfolio-06.jpg" alt="..." />
                </div>
                <div className="portfolio-content">
                    <div className="item">
                        <span className="text-white d-block mb-2 display-29"><span className="me-2"></span>Project</span>
                        <Link href="#"><h4 className="text-white">Benefits Research</h4></Link>
                    </div>
                    <Link href="#" className="portfolio-link"><i className="ti-link fs-4"></i></Link>
                </div>
            </div>
            </OwlCarousel>
    </section>

    <section className="bg-light">
            <div className="container">
                <div className="section-heading5">                    
                    <h2>Development <span className="text-secondary">Technology</span></h2>
                    <div className="divider"></div>
                </div>
                <div className="row mt-n1-9">
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/development/AngularJS.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/development/node.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/development/react.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/development/dotnetcore.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/development/aspdotnet.png" className="partner-logo" alt="..." />
                                </div>                               
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/development/aspdotnetmvc.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>   
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/development/php.png" className="partner-logo" alt="..." />
                                </div>                               
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/development/Python_logo.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 bg-very-light-gray border border-color-extra-light-gray">
									<img src="img/development/CSS3_and_HTML5_logos.png" className="partner-logo" alt="..." />                                    
                                </div>                                
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/development/HTML5.png" className="partner-logo" alt="..." />
                                </div>                               
                            </div>
                        </div>
                    </div>


                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 bg-very-light-gray border border-color-extra-light-gray">
									<img src="img/development/Android.png" className="partner-logo" alt="..." />                                    
                                </div>                                
                            </div>
                        </div>
                    </div>
                    
					
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/development/ios-app.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
					<div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/development/react-logo.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/development/flutter-logo.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/development/WordPress-Logo.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/development/magento-logo.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/development/MongoDB_logo.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/development/PostgreSQL_logo.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 bg-very-light-gray border border-color-extra-light-gray">
                                    <img src="img/development/MySQL.png" className="partner-logo" alt="..." />
                                </div>                                
                            </div>
                        </div>
                    </div>
					<div className="col-md-3 col-xl-3 mt-1-9">
                        <div className="card border-0 border-radius-0 h-100 box-shadow3">
                            <div className="card-body p-3 p-sm-4 text-center">
                                <div className="p-3 bg-very-light-gray border border-color-extra-light-gray">
									<img src="img/development/microsoftsqlserver.png" className="partner-logo" alt="..." />                                    
                                </div>                                
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </section>

        <section className="about-style-01">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 mb-5 mb-lg-0">
                        <div className="mt-1-9">
                            <div className="row g-0 align-items-center">
                                <div className="col-10">
                                    <div className="image1">
                                        <img src="/img/bg/Innovation_Technology.jpeg" alt="..." />
                                    </div>
                                </div>                            
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 ps-lg-2-2 ps-xl-2">                    
                        <h2 className="h1 mb-4">Innovations & <span className="text-secondary">Experiments</span></h2>
                        <p className="mb-1-9">Apart from the extension development, our team is always into doing some innovations and experiments to ease out the customer’s needs.</p>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-light pb-2">
            <div className="container">
                <div className="section-heading5">                    
                    <h2>Client  <span className="text-secondary">Testimonials</span></h2>
                    <p>What our clients say about us</p>
                    <div className="divider"></div>
                </div>
                <OwlCarousel className='owl-theme testimonial3-carousel owl-carousel text-center' {...options} loop nav>                
                    <div className="testimonial-wrapper">
                        <div className="testimonial-inner">
                            <div className="d-sm-flex align-items-center">                                
                                <div className="flex-grow-1 ms-sm-1-9 text-center text-sm-start">                                    
                                    <p className="mb-3">Adventus Technocrats Pvt. Ltd. have complteted our project request sucessfully and handed over the final deliverables 70% of the stipulated time. We really appriciate the thought put into the project by them and fullfillmnt of each small request at every stage of the project development. The output has been more than satisfactory and final tool has exceeded our initial expectations.</p>
                                    <h6 className="h5 mb-1">Sr. Admin Officer</h6>
                                    <span className="text-secondary">Placement Cell IIM Lucknow</span>                                                                        
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="testimonial-wrapper">
                        <div className="testimonial-inner">
                            <div className="d-sm-flex align-items-center">                                
                                <div className="flex-grow-1 ms-sm-1-9 text-center text-sm-start">                                    
                                    <p className="mb-3">I am writing this as a humble note of appreciation for implementing robust and result driver ERP software i.e. eAM at our various branches across Lucknow U.P. and also for outstanding technical support provided by your technical team. I would love to recommend your company to anyone who needs an ERP service in the future. Keep up the good work...</p>
                                    <h6 className="h5 mb-1">Dr. Prashant Kumar Mishra</h6>
                                    <span className="text-secondary">MD, Red Rose Group of Schools Lucknow</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="testimonial-wrapper">
                        <div className="testimonial-inner">
                            <div className="d-sm-flex align-items-center">                               
                                <div className="flex-grow-1 ms-sm-1-9 text-center text-sm-start">                                   
                                    <p className="mb-3">Adventus and the team put a lot of work and thoughts in to the solution that I asked them to create for me. I really appreciate the effort they put in to it.</p>
                                    <h6 className="h5 mb-1">Mrs. Mamta Dixit</h6>
                                    <span className="text-secondary">Principal, Lok Bharti Public Inter College Lucknow</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="testimonial-wrapper">
                        <div className="testimonial-inner">
                            <div className="d-sm-flex align-items-center">                                
                                <div className="flex-grow-1 ms-sm-1-9 text-center text-sm-start">                                    
                                    <p className="mb-3">Have been associated with Adventus team for over 10+ years. They do almost all of our software development and domain management. Their skills in ERP is exemplary. In fact we were considering implementing ERP using MS Dynamics for our manufacturing, however with our experiences with Adventus team, we were compelled to go for their customized ERP solution. We have always been delighted with their services.</p>
                                    <h6 className="h5 mb-1">Dr. Ravindra Dubey</h6>
                                    <span className="text-secondary">Saiens BioPharma Pvt. Ltd. Ahmedabad</span>
                                </div>
                            </div>
                        </div>
                    </div>
					
					<div className="testimonial-wrapper">
                        <div className="testimonial-inner">
                            <div className="d-sm-flex align-items-center">                               
                                <div className="flex-grow-1 ms-sm-1-9 text-center text-sm-start">                                   
                                    <p className="mb-3">A very good and reputable company giving out the best management software for schools and other managerial organizations this software has eased up things for the school which is in Nigeria the software is also good in other countries. It’s the best software for management so far.</p>
                                    <h6 className="h5 mb-1">Onuorah Kezie Jasper</h6>
                                    <span className="text-secondary">COO, JC Best Schools International Nigeria</span>
                                </div>
                            </div>
                        </div>
                    </div>
					
					<div className="testimonial-wrapper">
                        <div className="testimonial-inner">
                            <div className="d-sm-flex align-items-center">                                
                                <div className="flex-grow-1 ms-sm-1-9 text-center text-sm-start">                                    
                                    <p className="mb-3">eAM is best school erp for CBSE Pattern schools.</p>
                                    <h6 className="h5 mb-1">Mr. S.K. Singh</h6>
                                    <span className="text-secondary">Manager, Lucknow Model Public Inter College Lucknow</span>
                                </div>
                            </div>
                        </div>
                    </div>  

                    <div className="testimonial-wrapper">
                        <div className="testimonial-inner">
                            <div className="d-sm-flex align-items-center">                                
                                <div className="flex-grow-1 ms-sm-1-9 text-center text-sm-start">                                    
                                    <p className="mb-3">Very helpful and supportive team , deals in all type of software solutions</p>
                                    <h6 className="h5 mb-1">Mr. Ayush Bajpai</h6>
                                    <span className="text-secondary">RD Memorial Public School Lucknow</span>
                                </div>
                            </div>
                        </div>
                    </div>          
                    <div className="testimonial-wrapper">
                        <div className="testimonial-inner">
                            <div className="d-sm-flex align-items-center">                                
                                <div className="flex-grow-1 ms-sm-1-9 text-center text-sm-start">                                    
                                    <p className="mb-3">Great skills in their team , I am much impressed with their awesome & fast work.</p>
                                    <h6 className="h5 mb-1">Dr. Preetesh Saxena</h6>
                                    <span className="text-secondary">Director, HITM Lucknow</span>
                                </div>
                            </div>
                        </div>
                    </div>    
                    <div className="testimonial-wrapper">
                        <div className="testimonial-inner">
                            <div className="d-sm-flex align-items-center">                                
                                <div className="flex-grow-1 ms-sm-1-9 text-center text-sm-start">                                    
                                    <p className="mb-3">Best company for website</p>
                                    <h6 className="h5 mb-1">R.U. Dubey</h6>
                                    <span className="text-secondary">Mentech Security Services Lucknow</span>
                                </div>
                            </div>
                        </div>
                    </div>  
                    <div className="testimonial-wrapper">
                        <div className="testimonial-inner">
                            <div className="d-sm-flex align-items-center">                                
                                <div className="flex-grow-1 ms-sm-1-9 text-center text-sm-start">                                    
                                    <p className="mb-3">Excellent services</p>
                                    <h6 className="h5 mb-1">IP Agrahari</h6>
                                    <span className="text-secondary">DPG Sansthan Lucknow</span>
                                </div>
                            </div>
                        </div>
                    </div> 
                </OwlCarousel>

                <div className='row'>
                    <div className='col-md-12'>
                        <div className='text-center mt-4'>
                            <div className="service-button">
                                <Link to={'/Testimonials'} className="butn md"><i className="ti-arrow-right icon-arrow before"></i><span className="label">Read More</span><i className="ti-arrow-right icon-arrow after"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='mt-4 pt-5'>
                        <iframe title="Google Map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14233.543391171585!2d80.959509!3d26.891247!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399957c372315007%3A0xf5cb200626472254!2sAdventus%20Technocrats%20Private%20Limited!5e0!3m2!1sen!2sus!4v1703920524678!5m2!1sen!2sus" style={{width:'100%',height:'300px',border:'0'}}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </section>

    
</>
  )
}

export default Home
