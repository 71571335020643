import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Breadcrumb from '../Common/Breadcrumb';
const WebHosting = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <div>
     <Breadcrumb  title="Web Hosting" />

    <section>
    <div className="container">
        <div className="section-heading5 mx-auto">                
            <h2>Web <span className="text-secondary">Hosting</span></h2>
            <div className="divider"></div>
         </div>
        <div className="row mt-n1-9 mb-3">
            <div className="col-md-12">
                <p>Web hosting is a service that allows organizations and individuals to post a website or web page onto the internet. A web host, or web hosting service provider, is a business that provides the technologies and services needed for the website or webpage to be viewed on the internet. The host stores the files and data that make up a website and makes it accessible to computers connected to the internet. There are various types of web hosting services available, including shared hosting, dedicated hosting, and virtual private server (VPS) hosting.</p>
                <p>We help you establish and communicate your Unique Value Proposition (UVP) and Key Differentiation Point (KDP). When prospects come to your site or page, they must see a unique or particular advantage they would get from doing business with you, instead of your competitors.</p>
                <p>Not only do we create great websites or increase an existing site’s search rankings, visibility and traffic, our main focus and purpose is to ensure a good portion of that traffic takes action on your site.</p>
                <p>We monitor our clients’ traffic stats and conversion stats so we and the client always know how we’re doing. And for eCommerce sites, with our clients’ permission, we also monitor their online sales stats.</p>
            </div>
        </div>
    </div>
    </section>
</div>
  )
}

export default WebHosting
