import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

const Navbar = () => {
    const [activeItem, setActiveItem] = useState('home');

    const handleItemClick = (itemName) => {
        setActiveItem(itemName);
        console.log("clicked false")
        setClickedMenu(false)
    };

    const location = useLocation()
    console.log(location.pathname)

    const [clicked, setClickedMenu] = useState(false)


    let showMenu = () => {

        setClickedMenu(!clicked)
        console.log(clicked)
    }

    let handleLink = () => {
        console.log("clicked false")
        setClickedMenu(false)
    }
    //   let closeForm=()=>{
    //     setClicked(false)
    //   }

    const [isVisible, setIsVisible] = useState(false);
    const handleScroll = () => {
        setIsVisible(window.pageYOffset > 100);
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    // const [isHovered, setIsHovered] = useState(false);

    // const handleMouseEnter = () => {
    // setIsHovered(true);
    // };

    // const handleMouseLeave = () => {
    // setIsHovered(false);
    // };


    return (
        <>
            <header className={location.pathname === '/' ? "header-style1 menu_area-light fixedHeader" : "header-style1 menu_area-light fixedHeader scrollHeader"}>
                <div className="navbar-default">
                    <div className="container-fluid px-sm-2-9">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-12 px-0">
                                <div className="menu_area alt-font">
                                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                                        <div className="navbar-header navbar-header-custom px-3">
                                            <Link className="home-link navbar-brand logodefault position-relative"  to={'/'}>
                                                <div className='spira-logo'>
                                                    <div class="spiralContainer one">
                                                        <div class="spiral"> </div>
                                                    </div>
                                                    <div class="spiralContainer two">
                                                        <div class="spiral"> </div>
                                                    </div>
                                                    <div class="spiralContainer three">
                                                        <div class="spiral"> </div>
                                                    </div>
                                                </div>
                                                <img id="logo-img" height="100" width="200" class="img-fluid auto_size" src="img/logo.png" alt="logo-img" />
                                            </Link>
                                            {/* <Link to={'/'} className="navbar-brand logodefault"><img id="logo" src="img/logos/logo.png" alt="logo" /></Link> */}
                                        </div>
                                        <div className="navbar-toggler" onClick={showMenu}></div>
                                        <ul className="navbar-nav ms-auto" id="nav">
                                            <li className={activeItem === 'Home' ? 'active' : ''} ><Link to={'/'} onClick={() => handleItemClick('Home')}>Home</Link></li>
                                            <li className={activeItem === 'About' ? 'active' : ''}>
                                                <Link to={'/About'} onClick={() => handleItemClick('About')}>About Us <i className='fa fa-chevron-down'></i></Link>
                                                <ul>
                                                    <li><Link onClick={handleLink} to={'/About'}>About Adventus</Link></li>
                                                    <li><Link onClick={handleLink} to={'https://adventusindia.com/pdf/adventus_profile.pdf'} target='blank'>Company Profile</Link></li>
                                                    <li><Link onClick={handleLink} to={'/Testimonials'}>Client Testimonials</Link></li>
                                                </ul>
                                            </li>
                                            <li className={activeItem === 'Services' ? 'active' : ''}><Link className='has-sub' to={'/Services'} onClick={() => handleItemClick('Services')}>Services <i className='fa fa-chevron-down'></i></Link>
                                                <ul>
                                                    <li><Link onClick={handleLink} to={'/SchoolManagementSoftware'}>School Management Software</Link></li>
                                                    <li><Link onClick={handleLink} to={'/SchoolMobileApp'}>School Mobile App</Link></li>
                                                    <li><Link onClick={handleLink} to={'/RFIDSolution'}>RFID Solution</Link></li>
                                                    <li><Link onClick={handleLink} to={'/WebDesigning'}>Website Designing</Link></li>
                                                    <li><Link onClick={handleLink} to={'/CustomSoftwareDevelopment'}>Custom Software Development</Link></li>
                                                    <li><Link onClick={handleLink} to={'/BulkSMS'}>Bulk SMS Integration</Link></li>
                                                    <li><Link onClick={handleLink} to={'/BiometricIntegration'}>Biometric Integration</Link></li>
                                                    <li><Link onClick={handleLink} to={'/PaymentGateway'}>Payment Gateway Integration</Link></li>
                                                    <li><Link onClick={handleLink} to={'/DomainRegistration'}>Domain Name Registration</Link></li>
                                                    <li><Link onClick={handleLink} to={'/WebHosting'}>Web Hosting</Link></li>
                                                    <li><Link onClick={handleLink} to={'/EmailHosting'}>Email Hosting</Link></li>
                                                </ul>
                                            </li>
                                            <li className={activeItem === 'SchoolERP' ? 'active' : ''} ><Link to={'/SchoolERP'} onClick={() => handleItemClick('SchoolERP')}>School ERP</Link></li>
                                            {/* <li className={activeItem === 'Portfolio' ? 'active' : ''} ><Link to={'/Portfolio'} onClick={() => handleItemClick('Portfolio')}>Portfolio</Link></li> */}
                                            <li className={activeItem === 'Clientele' ? 'active' : ''} ><Link to={'/Clientele'} onClick={() => handleItemClick('Clientele')}>Clientele</Link></li>
                                            <li className={activeItem === 'ChannelPartner' ? 'active' : ''} ><Link to={'/ChannelPartner'} onClick={() => handleItemClick('ChannelPartner')}>Channel Partner</Link></li>
                                            <li className={activeItem === 'Contact' ? 'active' : ''} ><Link onclick="scrollToTop()" to={'/Contact'} onClick={() => handleItemClick('Contact')}>Contact Us</Link></li>
                                        </ul>

                                        {clicked && <ul className="navbar-nav ms-auto" id="nav" style={{ display: 'block' }}>
                                            <li className={activeItem === 'Home' ? 'active' : ''} ><Link to={'/'} onClick={() => handleItemClick('Home')}>Home</Link></li>
                                            <li className={activeItem === 'About' ? 'active' : ''}>
                                                <Link to={'/About'} onClick={() => handleItemClick('About')}>About Us <i className='fa fa-chevron-down'></i></Link>
                                                <ul>
                                                    <li><Link onClick={handleLink} to={'/About'}>About Adventus</Link></li>
                                                    <li><Link onClick={handleLink} to={'https://adventusindia.com/pdf/adventus_profile.pdf'} target='blank'>Company Profile</Link></li>
                                                    <li><Link onClick={handleLink} to={'/Testimonials'}>Client Testimonials</Link></li>
                                                </ul>
                                            </li>
                                            <li className={activeItem === 'Services' ? 'active' : ''}><Link className='has-sub' to={'/Services'} onClick={() => handleItemClick('Services')}>Services <i className='fa fa-chevron-down'></i></Link>
                                                <ul>
                                                    <li><Link onClick={handleLink} to={'/SchoolManagementSoftware'}>School Management Software</Link></li>
                                                    <li><Link onClick={handleLink} to={'/SchoolMobileApp'}>School Mobile App</Link></li>
                                                    <li><Link onClick={handleLink} to={'/RFIDSolution'}>RFID Solution</Link></li>
                                                    <li><Link onClick={handleLink} to={'/WebDesigning'}>Website Designing</Link></li>
                                                    <li><Link onClick={handleLink} to={'/CustomSoftwareDevelopment'}>Custom Software Development</Link></li>
                                                    <li><Link onClick={handleLink} to={'/BulkSMS'}>Bulk SMS Integration</Link></li>
                                                    <li><Link onClick={handleLink} to={'/BiometricIntegration'}>Biometric Integration</Link></li>
                                                    <li><Link onClick={handleLink} to={'/PaymentGateway'}>Payment Gateway Integration</Link></li>
                                                    <li><Link onClick={handleLink} to={'/DomainRegistration'}>Domain Name Registration</Link></li>
                                                    <li><Link onClick={handleLink} to={'/WebHosting'}>Web Hosting</Link></li>
                                                    <li><Link onClick={handleLink} to={'/EmailHosting'}>Email Hosting</Link></li>
                                                </ul>
                                            </li>
                                            <li className={activeItem === 'SchoolERP' ? 'active' : ''} ><Link to={'/SchoolERP'} onClick={() => handleItemClick('SchoolERP')}>School ERP</Link></li>
                                            {/* <li className={activeItem === 'Portfolio' ? 'active' : ''} ><Link to={'/Portfolio'} onClick={() => handleItemClick('Portfolio')}>Portfolio</Link></li> */}
                                            <li className={activeItem === 'Clientele' ? 'active' : ''} ><Link to={'/Clientele'} onClick={() => handleItemClick('Clientele')}>Clientele</Link></li>
                                            <li className={activeItem === 'ChannelPartner' ? 'active' : ''} ><Link to={'/ChannelPartner'} onClick={() => handleItemClick('ChannelPartner')}>Channel Partner</Link></li>
                                            <li className={activeItem === 'Contact' ? 'active' : ''} ><Link to={'/Contact'} onClick={() => handleItemClick('Contact')}>Contact Us</Link></li>
                                        </ul>}

                                        {/* <div className="attr-nav align-items-lg-center ms-lg-auto">
                                         <ul>                                           
                                            <li className="d-none d-xl-inline-block"><Link to={'/Contact'} className="butn md"><i className="ti-arrow-right icon-arrow before"></i><span className="label">Contact Us</span><i className="ti-arrow-right icon-arrow after"></i></Link></li> 
                                        </ul>
                                    </div>      */}
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <button className={`scroll-to-top ${isVisible ? 'visible' : ''}`}
                onClick={scrollToTop}><i className="fas fa-angle-up" aria-hidden="true"></i></button>
        </>
    )
}

export default Navbar
