// CookieConsent.js

import React, { useState } from 'react';

const CookieConsent = () => {
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    setAccepted(true);
  
  };

  if (accepted) {
    return null; 
  }

  return (
    <div className="cookie-consent">
      <p>This website uses cookies to enhance your browsing experience, serve personalised content, and analyse our traffic. By continuing to use this website, you accept our policy to use of cookies.</p>
      <button className="btn btn-info" onClick={handleAccept}>Reject All</button> <button className="btn btn-info" onClick={handleAccept}>Accept</button>
    </div>
  );
};

export default CookieConsent;
