import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom'
import { BASE_URL, TO_Email, CC_Email,Subject_Contact,Subject_Enquiry,Subject_ChannelPartner } from '../Constant';
import ReCAPTCHA from "react-google-recaptcha";
import AppLoader from '../Common/AppLoader';
const Contact = () => {
    useEffect(() => {
                window.scrollTo(0, 0);
            }, [])

  const initialFormData = {
                name: '',
                email: '',
                contactNo: '',
                message: '',
                // capcha:'',
              };
  const [formData, setFormData] = useState(initialFormData);
  const [isRecaptchaVerified, setRecaptchaVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Use an object to store the validation status for each field
  const [validationStatus, setValidationStatus] = useState({
    name: true,
    email: true,
    contactNo: true,
    message: true
    // capcha:true
  });
  const resetFormData = () => {
    setFormData(initialFormData);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate email format if the input name is 'email'
    if (name === 'email') {
      setValidationStatus((prevStatus) => ({
        ...prevStatus,
        email: validateEmail(value)
      }));
      setFormData({ ...formData, [e.target.name]: e.target.value });
    
    }
    else{
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // Reset validation status when the user types in the field
        setValidationStatus({ ...validationStatus, [e.target.name]: true });
    }
  
  };
  const handleRecaptchaChange = (value) => {
    // This callback is triggered when reCAPTCHA is verified
    setRecaptchaVerified(true);
  };
  const validateEmail = (email) => {
    // Simple email validation, you can enhance it based on your requirements
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
      const isValid = Object.keys(formData).every(key => {
        if (key === 'email' && !validateEmail(formData[key])) {
          setValidationStatus({ ...validationStatus, [key]: false });
          return false;
        }
        if (!formData[key]) {
          setValidationStatus({ ...validationStatus, [key]: false });
          return false;
        }
        return true;
      });
      if (!isValid) 
      {
        if(formData.name && formData.email && formData.contactNo && formData.message)
        {
            alert('Enter Email valid address');
        }
        else{
            alert('Please fill in all required fields.');
        }
       
        return;
      }
      if (isRecaptchaVerified) 
      {
        setIsLoading(true);
const apiUrl = BASE_URL;  // Update the URL to match your API endpoint
const requestData = {
  name: formData.name,
  email: formData.email,
  toEmail: TO_Email,
  ccEmail: CC_Email,
  subject: Subject_Contact,
  contact: formData.contactNo,
  message: formData.message
};

fetch(apiUrl, {
  method: 'POST',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(requestData)
})
  .then(response => response.text())
  .then(data => {
    // Handle the response data
    console.log('Response:', data);

    if (data.includes("Send Success")) {
      alert("Your Request is submitted Successfully");
      setIsLoading(false);
      resetFormData();
    } else {
      // Handle other cases if needed
      alert("Request failed");
      setIsLoading(false);
    }
  })
  .catch(error => {
    // Handle errors
    alert('Error:', error)  
    setIsLoading(false);

    console.error('Error:', error);
  });
      }
      else {
        // console.log("Please complete the reCAPTCHA verification.");
         alert('Please complete the reCAPTCHA verification.');
       }
    };

  return (
       <div>
          <AppLoader loading={isLoading} />
        <section className="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7" style ={{backgroundImage: 'url("img/bg/bg-19.jpg")'}}>
            <div className="row position-relative z-index-1">
                <div className="col-md-12">
                    <div className="faded-text">
                        <div className="f-text"><span className="main-font">Contact Us</span></div>
                    </div>
                    <h1>Contact Us</h1>
                    <ul className="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>Contact Us</span></li>
                    </ul>
                </div>
            </div>
        </section>

       

        {/* <!-- CONTACT FORM
        ================================================== --> */}
        <section className="bg-light pb-2 mb-0">
            <div className="container">
                <div className="section-heading5">
                    {/* <span className="sub-title">Get in touch</span> */}
                    <h2>Contact <span className="text-secondary">Us</span></h2>
                    <div className="divider"></div>
                </div>
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-7 mb-2-2 mb-lg-0">
                        <div className="p-1-9 p-lg-2-2 box-shadows h-100">                           
                            <form className="contact quform" onSubmit={handleSubmit}>
                                <div className="quform-elements">
                                    <div className="row">

                                        {/* <!-- Begin Text input element --> */}
                                        <div className="col-md-12 pb-2">                                           
                                            <div class="input-group quform-element form-group">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i className='fa fa-user'></i>
                                                </span>
                                                <input type="text" class="form-control" placeholder="Name" 
                                                aria-label="Input group example" aria-describedby="basic-addon1"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        {/* <!-- End Text input element -->

                                        <!-- Begin Text input element --> */}

                                        <div className="col-md-12 pb-2">                                           
                                            <div class="input-group quform-element form-group">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i className='fa fa-envelope'></i>
                                                </span>
                                                <input type="text" class="form-control" placeholder="Email" 
                                                aria-label="Input group example" aria-describedby="basic-addon1"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                       
                                        <div className="col-md-12 pb-2">                                           
                                            <div class="input-group quform-element form-group">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i className='fas fa-phone-alt fs2'></i>
                                                </span>
                                                <input type="text" class="form-control" placeholder="Contact No." 
                                                aria-label="Input group example" aria-describedby="basic-addon1"
                                                name="contactNo"
                                                value={formData.contactNo}
                                                onChange={handleChange}
                                                
                                                />
                                            </div>
                                        </div>
                                        {/* <!-- End Text input element -->

                                        <!-- Begin Textarea element --> */}
                                        <div className="col-md-12">
                                            <div className="quform-element form-group">
                                                {/* <label for="message">Message <span className="quform-required">*</span></label> */}
                                                <div className="quform-input">
                                                    <textarea className="form-control" rows="3" placeholder="Message"
                                                      name="message"
                                                      value={formData.message}
                                                      onChange={handleChange}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Textarea element -->

                                        <!-- Begin Captcha element --> */}
                                        <div className="col-md-12">
                                            <div className="quform-element">
                                                {/* <div className="form-group">
                                                    <div className="quform-input">
                                                        <input className="form-control" type="text" 
                                                        placeholder="Type the below word"
                                                        name="capcha"
                                                        value={formData.capcha}
                                                        onChange={handleChange}
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className="form-group">
                                                    <div className="quform-captcha">
                                                        <div className="quform-captcha-inner">
                                                            {/* <img src="/img/courier-new-light.png" alt="..." /> */}
                                                            <ReCAPTCHA sitekey="6LeZjXYpAAAAALzSSr8D_JzY2j7DVV0C8A51Trc0" onChange={handleRecaptchaChange}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Captcha element -->

                                        <!-- Begin Submit button --> */}
                                        <div className="col-md-12">
                                            <div className="quform-submit-inner">
                                                <button type="submit" className="butn border-0"><i className="ti-arrow-right icon-arrow before"></i><span className="label">Submit</span><i className="ti-arrow-right icon-arrow after"></i></button>
                                            </div>                                            
                                        </div>
                                        {/* <!-- End Submit button --> */}

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="box-shadows p-1-9 p-lg-2-2 h-100">
                            {/* <h2 className="mb-3 text-primary text-capitalize h3">Our contact details</h2> */}
                            <div className="d-flex mb-4 pb-3 border-bottom border-color-extra-light-gray">
                                <div className="flex-shrink-0 mt-2">
                                    <i className="fas fa-phone-alt text-secondary fs-2"></i>
                                </div>
                                <div className="flex-grow-1 ms-4">
                                    <h3 className="h5">Phone Numbers</h3>
                                    <span class="text-muted d-block mb-1"><a href="tel:+915223541430">+91 522 3541430</a></span>                                    
                                    <span className="text-muted"><a href="tel:+919415422074">+91 9415422074</a></span>
                                </div>
                            </div>
                            <div className="d-flex mb-4 pb-3 border-bottom border-color-extra-light-gray">
                                <div className="flex-shrink-0 mt-2">
                                    <i className="far fa-envelope-open text-secondary fs-2"></i>
                                </div>
                                <div className="flex-grow-1 ms-4">
                                    <h3 className="h5 mb-1">Email Address</h3>                                    
                                    <span className="text-muted">info@adventusindia.com</span>
                                </div>
                            </div>
                            <div className="d-flex mb-4 pb-3 border-bottom border-color-extra-light-gray">
                                <div className="flex-shrink-0 mt-2">
                                    <i className="fas fa-map-marker-alt text-secondary fs-2"></i>
                                </div>
                                <div className="flex-grow-1 ms-4">
                                    <h3 className="h5 mb-1">Location</h3>
                                    <address className="text-muted d-block mb-0 w-md-80 w-xl-70">315, 2nd Floor, Sector 9, Vikas Nagar, Lucknow 226022 U.P. India</address>
                                </div>
                            </div>
                            {/* <ul className="social-box">
                                <li>
                                    <Link to={'https://www.facebook.com/adventusin/'} target='blank'><i className="fab fa-facebook-f"></i></Link>
                                </li>
                                <li>
                                    <Link to={'https://twitter.com/atplup'} target='blank'><i className="fab fa-x"><img src='img/icons/twitter.png' style={{height:'15px'}} alt='' /></i></Link>
                                </li>                               
                                <li>
                                    <Link to={'https://www.instagram.com/adventusindia/'} target='blank'><i className="fab fa-instagram"></i></Link>
                                </li>
                                <li>
                                    <Link to={'https://www.linkedin.com/company/adventusindia/'} target='blank'><i className="fab fa-linkedin-in"></i></Link>
                                </li>
                                <li>
                                    <Link to={'https://www.youtube.com/@eam365'} target='blank'><i className="fab fa-youtube"></i></Link>
                                </li>
                            </ul>                             */}
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-12'>
                    <div className='mt-4 pt-3'>
                        <iframe title="Google Map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14233.543391171585!2d80.959509!3d26.891247!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399957c372315007%3A0xf5cb200626472254!2sAdventus%20Technocrats%20Private%20Limited!5e0!3m2!1sen!2sus!4v1703920524678!5m2!1sen!2sus" style={{width:'100%',height:'300px',border:'0'}}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </section>
        
     </div>
  );
};

export default Contact;

