import React, { useEffect } from 'react'
import { } from 'react-router-dom'
import Breadcrumb from '../Common/Breadcrumb';
const TravelHospitality = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <div>
        <Breadcrumb  title="Travel & Hospitality" />
        <section className="bg-light">
            <div className="container">
                <div class="card-body">            
                    <div className="section-heading5">                       
                        <h2>Travel & <span className="text-secondary">Hospitality</span></h2>
                        <div className="divider"></div>
                    </div>
                    {/* <h3>The travel and hospitality industry has undergone significant transformations in recent years</h3> */}
                    <p>The travel and hospitality industry has undergone significant transformations in recent years, influenced by technological advancements, shifting consumer behaviors, and external factors such as the global pandemic. This study investigates the dynamic nature of the industry, addressing challenges and showcasing innovative approaches that redefine the customer experience.</p>
                    <p>This study examines the current landscape of the travel and hospitality industry, focusing on emerging trends, key challenges, and innovative strategies adopted by businesses to adapt to changing consumer preferences and global circumstances. Through a combination of market analysis, case studies, and expert interviews, the research aims to provide insights into the industry's evolution and offer recommendations for sustainable growth.</p>
                </div>
            </div>
        </section>
    </div>
  )
}

export default TravelHospitality
