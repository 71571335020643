import React, { useState,useEffect } from 'react';
import { BASE_URL, TO_Email, CC_Email,Subject_Contact,Subject_Enquiry,Subject_ChannelPartner } from '../Constant';
import ReCAPTCHA from "react-google-recaptcha";
import AppLoader from '../Common/AppLoader';
const Inquiry = () => {
    const [clicked, setClicked]= useState(false)
    let showForm=()=>{
      setClicked(!clicked)
      console.log(clicked)
    }
    let closeForm=()=>{
      setClicked(false)
    }
    const initialFormData = {
        name: '',
        email: '',
        contactNo: '',
        message: ''
        // capcha:'',
      };
const [formData, setFormData] = useState(initialFormData);
const [isRecaptchaVerified, setRecaptchaVerified] = useState(false);
const [isLoading, setIsLoading] = useState(false);

// Use an object to store the validation status for each field
const [validationStatus, setValidationStatus] = useState({
name: true,
email: true,
contactNo: true,
message: true
// capcha:true
});
const resetFormData = () => {
    setFormData(initialFormData);
  };
  const handleRecaptchaChange = (value) => {
    // This callback is triggered when reCAPTCHA is verified
    setRecaptchaVerified(true);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate email format if the input name is 'email'
    if (name === 'email') {
      setValidationStatus((prevStatus) => ({
        ...prevStatus,
        email: validateEmail(value)
      }));
      setFormData({ ...formData, [e.target.name]: e.target.value });
    
    }
    else{
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // Reset validation status when the user types in the field
        setValidationStatus({ ...validationStatus, [e.target.name]: true });
    }
  
  };
  const validateEmail = (email) => {
    // Simple email validation, you can enhance it based on your requirements
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
      const isValid = Object.keys(formData).every(key => {
        if (key === 'email' && !validateEmail(formData[key])) {
          setValidationStatus({ ...validationStatus, [key]: false });
          return false;
        }
        if (!formData[key]) {
          setValidationStatus({ ...validationStatus, [key]: false });
          return false;
        }
        return true;
      });
      if (!isValid) 
      {
        if(formData.name && formData.email && formData.contactNo && formData.message)
        {
            alert('Enter Email valid address');
        }
        else{
            alert('Please fill in all required fields.');
        }
       
        return;
      }
           // Check if reCAPTCHA is verified before submitting the form
    if (isRecaptchaVerified) 
    {
      setIsLoading(true);
const apiUrl = BASE_URL;  // Update the URL to match your API endpoint
const requestData = {
  name: formData.name,
  email: formData.email,
  toEmail: TO_Email,
  ccEmail: CC_Email,
  subject: Subject_Enquiry,
  contact: formData.contactNo,
  message: formData.message
};

fetch(apiUrl, {
  method: 'POST',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(requestData)
})
  .then(response => response.text())
  .then(data => {
    // Handle the response data
    console.log('Response:', data);

    if (data.includes("Send Success")) {
      alert("Your Request is submitted Successfully");
      resetFormData();
      setIsLoading(false);
    } else {
      // Handle other cases if needed
      alert("Request failed");
      setIsLoading(false);
    }
  })
  .catch(error => {
    // Handle errors
    alert('Error:', error)
    setIsLoading(false);
    console.error('Error:', error);
  });
} 
else {
 // console.log("Please complete the reCAPTCHA verification.");
  alert('Please complete the reCAPTCHA verification.');
}
    };


  return (
    <div>
          <AppLoader loading={isLoading} />
        <div id="wpcs_tab_17489" className="wpcs_tab"><span href='#' onClick={showForm} className='text-white'>Enquiry Form</span></div>
        {clicked && <div id="copy-code" className="mfp-hide" style={{display:'block'}}>        
            <div className="page-right-sidebar-inner">      
                <button className="btn-close" onClick={closeForm}></button>
                <div className="right-sidebar-content">
                  <div className="row justify-content-end mt-3">
                <div className="col-lg-3 mb-2-2 mb-lg-0">
                  <div className="p-1-9 p-lg-2-2 box-shadows h-100">
                    <h2 className="mb-2-5">Enquiry <span className='text-secondary'>Form</span></h2>
                    <form className="contact quform" onSubmit={handleSubmit}>
                                <div className="quform-elements">
                                    <div className="row">

                                        {/* <!-- Begin Text input element --> */}
                                        <div className="col-md-12 pb-2">                                           
                                            <div class="input-group quform-element form-group">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i className='fa fa-user'></i>
                                                </span>
                                                <input type="text" class="form-control h-32" placeholder="Name" 
                                                aria-label="Input group example" aria-describedby="basic-addon1"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        {/* <!-- End Text input element -->

                                        <!-- Begin Text input element --> */}

                                        <div className="col-md-12 pb-2">                                           
                                            <div class="input-group quform-element form-group">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i className='fa fa-envelope'></i>
                                                </span>
                                                <input type="text" class="form-control h-32" placeholder="Email" 
                                                aria-label="Input group example" aria-describedby="basic-addon1"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                       
                                        <div className="col-md-12 pb-2">                                           
                                            <div class="input-group quform-element form-group">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i className='fas fa-phone-alt fs2'></i>
                                                </span>
                                                <input type="text" class="form-control h-32" 
                                                placeholder="Contact No." aria-label="Input group example" 
                                                aria-describedby="basic-addon1" 
                                                name="contactNo"
                                                value={formData.contactNo}
                                                onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        {/* <!-- End Text input element -->

                                        <!-- Begin Textarea element --> */}
                                        <div className="col-md-12">
                                            <div className="quform-element form-group">                                              
                                                <div className="quform-input">
                                                    <textarea className="form-control" rows="2" placeholder="Message"
                                                   name="message"
                                                   value={formData.message}
                                                   onChange={handleChange}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Textarea element -->

                                        <!-- Begin Captcha element --> */}
                                        <div className="col-md-12">
                                            <div className="quform-element">
                                                {/* <div className="form-group">
                                                    <div className="quform-input">
                                                        <input className="form-control" type="text" placeholder="Type the below word" 
                                                          name="capcha"
                                                          value={formData.capcha}
                                                          onChange={handleChange}
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className="form-group">
                                                    <div className="quform-captcha">
                                                        <div className="quform-captcha-inner">
                                                            {/* <img src="/img/courier-new-light.png" alt="..." /> */}
                                                            <ReCAPTCHA sitekey="6LeZjXYpAAAAALzSSr8D_JzY2j7DVV0C8A51Trc0" onChange={handleRecaptchaChange}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Captcha element -->

                                        <!-- Begin Submit button --> */}
                                        <div className="col-md-12">
                                            <div className="quform-submit-inner">
                                                <button type="submit" className="butn border-0"><i className="ti-arrow-right icon-arrow before"></i><span className="label">Submit</span><i className="ti-arrow-right icon-arrow after"></i></button>
                                            </div>                                            
                                        </div>
                                        {/* <!-- End Submit button --> */}

                                    </div>
                                </div>
                            </form>
                  </div>
                </div>                   
              </div>
                </div>
            </div>
        </div>}
    </div>
  )
}

export default Inquiry
