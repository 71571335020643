import React, { useEffect } from 'react'
import { } from 'react-router-dom'
import Breadcrumb from '../Common/Breadcrumb';
const WebDesigning = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <div>
    <Breadcrumb  title="Web Designing" />

    <section>
    <div className="container">
        <div className="section-heading5 w-lg-70 w-xl-70 w-xxl-70 mx-auto">                
            <h2>Web <span className="text-secondary">Designing</span></h2>
            <div className="divider"></div>
         </div>
        <div className="row mt-n1-9 mb-3">
            <div className="col-md-12">
                <p>Web designing is the process of creating websites and web applications with the use of HTML, CSS, JavaScript, and other programming languages. It involves designing the layout, graphics, images, and content of a website to create a visually appealing and user-friendly experience for visitors. Web designers must have a strong understanding of color theory, typography, user experience (UX) design, and responsive design principles. They use various design tools, such as Adobe Creative Suite, Sketch, and Figma, to create wireframes, mockups, and prototypes of their designs. Web designers must also collaborate with developers to ensure their designs are functional and compatible with different devices and browsers.</p>
            </div>
        </div>
    </div>
    </section>
</div>
  )
}

export default WebDesigning
