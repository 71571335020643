import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const Services = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <div>
        <section class="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7"   style = {{backgroundImage: 'url("img/bg/bg-19.jpg")'}}>
            <div class="row position-relative z-index-1">
                <div class="col-md-12">
                    <div class="faded-text">
                        <div class="f-text"><span class="main-font">Services</span></div>
                    </div>
                    <h1>Our Services</h1>
                    <ul class="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>Our Services</span></li>
                    </ul>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Services
