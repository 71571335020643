import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const SearchEngineOptimization = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <div>
        <section class="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7"   style = {{backgroundImage: 'url("img/bg/bg-19.jpg")'}}>
            <div class="row position-relative z-index-1">
                <div class="col-md-12">
                    <div class="faded-text">
                        <div class="f-text"><span class="main-font">Search Engine Optimization</span></div>
                    </div>
                    <h1>Search Engine Optimization</h1>
                    <ul class="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>Search Engine Optimization</span></li>
                    </ul>
                </div>
            </div>
        </section>

        <section>
        <div className="container">
            <div className="section-heading5 w-lg-70 w-xl-70 w-xxl-70 mx-auto">                
                <h2>Search Engine <span className="text-secondary">Optimization</span></h2>
                <div className="divider"></div>
             </div>
            <div className="row mt-n1-9 mb-3">
                <div className="col-md-12">
                    <p>SEO is the practice of optimizing a website and its content to increase its visibility and ranking in search engine results pages (SERP). This is achieved through a combination of on-page and off-page optimization techniques, such as keyword research, content creation, link building, meta tags, and technical optimization. The goal of SEO is to increase organic traffic and improve website ranking for relevant keywords, ultimately leading to increased visibility and credibility for a website.</p>
                </div>
            </div>
        </div>
        </section>
    </div>
  )
}

export default SearchEngineOptimization
