import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const SchoolManagementSoftware = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <div>
        <section class="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7"   style = {{backgroundImage: 'url("img/bg/bg-19.jpg")'}}>
            <div class="row position-relative z-index-1">
                <div class="col-md-12">
                    <div class="faded-text">
                        <div class="f-text"><span class="main-font">School Management Software</span></div>
                    </div>
                    <h1>School Management Software</h1>
                    <ul class="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>Portfolio</span></li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="bg-light">
            <div className="container">
                <div class="card-body">            
                    <div className="section-heading5">                       
                        <h2>School Management <span className="text-secondary">Software</span></h2>
                        <div className="divider"></div>
                    </div>
                </div>
                <div className='row mb-5'>
                    <div className='col-md-12'>
                        <p>School management software refers to a digital tool or application designed to streamline and automate various administrative and academic processes within educational institutions. These software solutions are aimed at enhancing efficiency, communication, and organization across different departments within schools, colleges, and universities. Here are some common features and functionalities typically found in school management software:</p>
                    </div>
                </div>
                <div className="row mt-n1-9 mb-3">
                    <div className="col-md-7">
                        <ul class="list-style3 mb-4">
                            <li>Student Management</li>
                            <li>Fee Management</li>
                            <li>Transport Management</li>
                            <li>Examination Management</li>
                            <li>Accounts & Inventory Management</li>
                            <li>Library Management</li>
                            <li>HR Management</li>
                            <li>Hostel Management</li>
                            <li>Parents Portal</li>
                            <li>Alumni Portal</li>
                        </ul>
                    </div>
                    <div className="col-md-5">
                        <div className="image1"><img src="/img/content/School-Management-Software.jpg" alt="..." /></div>
                    </div>
                </div>
            </div>            
        </section>
    </div>
  )
}

export default SchoolManagementSoftware
