import React, { useEffect } from 'react'
import { } from 'react-router-dom'
import Breadcrumb from '../Common/Breadcrumb';
// import './innernew.css';
const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <div>
        <Breadcrumb  title="About Us" />
        <section class="about-style-01">
            <div className="container">
                <div className="section-heading5">                    
                    <h2>About <span className="text-secondary">Adventus</span></h2>
                    <div className="divider"></div>
                </div>
            </div>
            <div class="container">
                <div class="row align-items-center">                
                    <div class="col-lg-12 col-md-12"> 
                        <p class="mb-1-9">We are a leading software company, specializing in developing innovative solutions for businesses of all sizes. With a team of experts in various fields, we strive to provide custom solutions that meet the unique needs of our clients. Our goal is to help companies achieve their goals through technology.</p>
                        <p>Adventus specializes in educational technology and use technology to improve teaching and learning. We offer a wide range of products and services, including educational software, e-learning platforms, online learning management systems, interactive whiteboards, virtual classrooms, and mobile learning applications. We aim to make education more accessible, engaging, and effective for students, teachers, and schools. We work with schools, universities, and other educational institutions to provide cutting- edge technology and support to help them achieve their goals.</p>
                        <p>Adventus is dedicated to revolutionizing the way people learn. Utilizing cutting-edge technology, Adventus provides innovative solutions to help students, educators, and institutions reach their full potential. With a focus on user-friendly design and intuitive interfaces. From online courses to interactive textbooks, Adventus is committed to continuously improving education for the modern world. Adventus is dedicated to revolutionizing the way people learn. Utilizing cutting-edge technology, Adventus provides innovative solutions to help students, educators, and institutions reach their full potential. With a focus on user-friendly design and intuitive interfaces. From online courses to interactive textbooks, Adventus is committed to continuously improving education for the modern world.</p>
                        <h3 class="h3 mb-4">Vision</h3>
                        <p>Our vision is to make education more accessible, more engaging, and more effective for all. We believe that our technology can help bridge the education gap and create a better world for everyone.</p>
                        <h3 class="h3 mb-4">Mission</h3>    
                        <p>Our mission is to enhance and improve the educational experience for students by utilizing technology. This can include developing innovative software and hardware products, offering online learning platforms, and providing educational resources and tools to teachers and students.</p>                
                    </div>
                </div>
            </div>
        </section>

        

    </div>
  )
}

export default About
