import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const BulkSMS = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <div>
        <section class="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7"   style = {{backgroundImage: 'url("img/bg/bg-19.jpg")'}}>
            <div class="row position-relative z-index-1">
                <div class="col-md-12">
                    <div class="faded-text">
                        <div class="f-text"><span class="main-font">Bulk SMS</span></div>
                    </div>
                    <h1>Bulk SMS</h1>
                    <ul class="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>Bulk SMS</span></li>
                    </ul>
                </div>
            </div>
        </section>

        <section>
        <div className="container">
            <div className="section-heading5 mx-auto">                
                <h2>Bulk <span className="text-secondary">SMS</span></h2>
                <div className="divider"></div>
             </div>
            <div className="row mt-n1-9 mb-3">
                <div className="col-md-12">
                    <p>Bulk SMS refers to a large number of SMS messages that are sent and received at once. This method of communication is often used for marketing and advertising purposes, as it allows businesses to reach a large audience quickly and efficiently. The messages are usually sent from a bulk SMS service provider, using a web-based platform or software. The process is simple and cost-effective, making it a popular choice for businesses of all sizes.</p>
                    <p>In this age of mobile revolution, it is extremely important to always stay in touch with technology. Bulk SMS are the fastest way for conveying information within groups to several members, just within seconds. However, with the ever so strict rules and limitations on messaging services, online bulk SMS service comes as a boon to all tech savvy individuals. With Adventus, you can easily send SMS online to any mobile number in India and abroad using a very simple procedure.</p>
                </div>
            </div>
        </div>
        </section>
    </div>
  )
}

export default BulkSMS
