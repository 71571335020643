import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const SchoolERP = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <div>
        <section class="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7"   style = {{backgroundImage: 'url("img/bg/bg-19.jpg")'}}>
            <div class="row position-relative z-index-1">
                <div class="col-md-12">
                    <div class="faded-text">
                        <div class="f-text"><span class="main-font">School ERP</span></div>
                    </div>
                    <h1>School ERP</h1>
                    <ul class="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>School ERP</span></li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="bg-light">
            <div className="container">
                <div class="card-body">            
                    <div className="section-heading5">                       
                        <h2>School <span className="text-secondary">ERP</span></h2>
                        <div className="divider"></div>
                    </div>
                </div>
                <div className='row mb-5'>
                    <div className='col-md-12'>
                    <p>School ERP (Enterprise Resource Planning) software is a comprehensive solution designed to streamline and automate various administrative and academic processes within educational institutions. These systems are tailored to meet the specific needs of schools, colleges, and universities, helping them manage tasks efficiently. Here are some common features and functionalities found in School ERP software:</p>
                    </div>
                </div>
                <div className="row mt-n1-9 mb-3">
                    <div className="col-md-7">                        
                        <ul class="list-style3 mb-4">
                            <li>Student Management</li>
                            <li>Fee Management</li>
                            <li>Transport Management</li>
                            <li>Examination Management</li>
                            <li>Accounts & Inventory Management</li>
                            <li>Library Management</li>
                            <li>HR Management</li>
                            <li>Hostel Management</li>
                            <li>Parents Portal</li>
                            <li>Alumni Portal</li>
                        </ul>                       
                    </div>
                    <div className="col-md-5">
                        <div className="image1"><img src="/img/content/online-school-software.png" alt="..." /></div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-12'>
                    <p>When choosing a School ERP software, it's essential to consider factors such as user-friendliness, scalability, customization options, customer support, and integration capabilities with other tools and systems.</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default SchoolERP
