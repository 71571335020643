import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <div>
        <section className="page-title-section bg-img cover-background primary-overlay" data-overlay-dark="7" style ={{backgroundImage: 'url("img/bg/bg-19.jpg")'}}>
            <div className="row position-relative z-index-1">
                <div className="col-md-12">
                    <div className="faded-text">
                        <div className="f-text"><span className="main-font">Terms</span></div>
                    </div>
                    <h1>Terms & Conditions</h1>
                    <ul className="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><span>Terms & Conditions</span></li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="bg-light">
            <div className="container">
                <div class="card-body">            
                    <div className="section-heading5">
                        {/* <span className="sub-title">Get in touch</span> */}
                        <h2>Terms & <span className="text-secondary">Conditions</span></h2>
                        <div className="divider"></div>
                    </div>            
                
                    <p>In order to send you information and product service offers that will more likely appeal to you, we may ask you to provide some information about yourself and your interests. Your participation is, of course, voluntary. We hope that you will want to take part in these special offers and opportunities, but we respect our customers' privacy.</p>
                    <div class="row mb-2-2">
                        <div class="col-lg-12">
                            <h3>Illegal use</h3>
                            <p>Company may be used only for lawful purposes. Transmission, distribution or storage of any material in violation of any applicable law or regulation is prohibited. This includes, without limitation, material protected by copyright, trademark, trade secret or other intellectual property right used without proper authorisation, and material that is obscene, defamatory, constitutes an illegal threat, and violates export control laws or anti national.</p>
                            <h3>Subscriber conduct</h3>
                            <p>Subscriber shall use Company services for lawful purposes only. Subscriber shall not post or transmit using Company services any material which violates or infringes in any way upon the rights of others, which is unlawful, threatening, abusive, defamatory, invasive of privacy or publicity rights, vulgar, obscene, profane or otherwise objectionable, which encourages conduct that would constitute a criminal offence, give right to civil liability or otherwise violate any law, or which, without the Company express prior approval, contains advertising or any solicitation with respect to products or services. Any conduct by a Subscriber that in the Company discretion restricts or inhibits any other Subscriber from using or enjoying Company services will not be permitted. Subscriber shall not use Company services to advertise or perform any commercial solicitation, including, but not limited to, the solicitation of users to become subscribers of other on-line information services competitive with the Company services. Subscriber shall not upload, post or otherwise make available using Company services any material protected by copyright, trademark or other proprietary right without the express permission of the owner of the copyright, trademark or other proprietary right and the burden of determining that any material is not protected by copyright rests with subscriber. Subscriber shall be solely liable for any damage resulting from any infringement of copyrights, proprietary rights, or any other harm resulting from such a submission.</p>
                            <h3>System and Network Security</h3>
                            <p>Violation of system or network security is prohibited, and may result in criminal and Civil Liability. The Company will investigate incidents involving such violations and may involve/will cooperate with law enforcement agencies if a criminal violation is suspected. Examples of system or network security violations include, without limitation, the following:</p>
                            <ul class="list-style3 mb-4">
                                <li>Unauthorized access to or use of data, systems or networks, including any attempt to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without express authorization of the owner of the system or network.</li>
                                <li>Unauthorized monitoring of data or traffic on any network or system without express authorization of the owner of the system or network.</li>
                                <li>Interference with service to any user, host or network including, without limitation, mail bombing, flooding, deliberate attempts to overload a system and broadcast attacks.</li>
                                <li>Forgery of any identification or obscuring of hostnames, usernames, IP addresses, or any message header information in any data.</li>
                                <li>Harassing or threatening transmissions.</li>
                                <li>Probing for means of gaining unauthorized access to computers or networks.</li>
                                <li>Introducing or causing to be introduce any computer contaminant or computer viruses into system or network.</li>
                                <li>Introducing or causing to introduce any pornography material, SMS etc.</li>

                                </ul>
                            <h3>Indemnification</h3>
                            <p>Subscribers agree to defend, indemnify and hold harmless the Company, its affiliates and their respective directors, officers, employees and agents from and against all claims and expenses, including attorney's fees, arising out of the use of Company Services by subscriber or any body else than the Subscriber, if any.</p>
                            <h3>Enforcement of Terms & Conditions for Company subscribers</h3>
                            <p>Indirect or attempted violations of the policy and actual or attempted violations by a third party on behalf of a Company Dealer or a Dealer's Customer shall be considered violation of the policy by such customer or end user. Any user found violating the above T&C is liable for action under the prevailing laws including termination of services without any prior notice.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       
    </div>

  )
}

export default Terms
